import gsap from "gsap";

export default class HomeButton
{
	constructor (manager)
	{
		this.stateMngr = manager.state;

		this.active = false;
		this.enabled = false;
	}

	init ()
	{
		const root = document.querySelector("#bat_UI");

		this.menuElement = document.createElement("div");
		this.menuElement.style.visibility = "hidden";
		this.menuElement.style.opacity = 0;
		this.menuElement.className = "btn_home";
		root.appendChild(this.menuElement);

		this.menuElement.addEventListener("mousedown", evt => this.onTouch(evt), false);
	}

	show ()
	{
		this.active = true;

		//fade in
		gsap.to(this.menuElement, {
			duration: 1,
			autoAlpha: 1,
			delay: 1.5,
			onComplete: () =>
			{
				this.enabled = true;
			}
		});
	}

	hide ()
	{
		this.enabled = false;

		//fade out
		gsap.to(this.menuElement, {
			duration: 1,
			autoAlpha: 0,
			delay: 0,
			onComplete: () =>
			{
				this.active = false;
			}
		});
	}

	onTouch (evt)
	{
		evt.stopPropagation();
		if (!this.enabled) return;
		if (!this.active) return;

		//Go back to devices menu
		this.stateMngr.changeState(this.stateMngr.states.DEVICE_MENU);
		this.enabled = false;
	}
}
