/*
 *  Devices Edu
 *  Creates 3D Devices for Education Tablet
 */

import * as THREE from "three";
import gsap from "gsap";
import DeviceHelper from "./device";

export default class DevicesEdu
{
	constructor (manager)
	{
		this.manager = manager;
		this.enabled = false;
		this.active = false;

		this.brandDevices = {};
		this.currentBrand = "";
		this.allDevices = [];

		manager.addToUpdate(() => this.update(), "devicesEdu");

		//Register for device changes
		manager.state.addEventListener("changeDevice", (id) => this.changeDevice(id));
	}

	init (json, lookup)
	{
		//Set material texture
		const tex = this.manager.loader.getAsset("devices_tx");
		tex.wrapS = THREE.RepeatWrapping;
		tex.wrapT = THREE.RepeatWrapping;
		tex.premultiplyAlpha = true;

		//Create material
		this.deviceMat = new THREE.MeshBasicMaterial();
		this.deviceMat.map = tex;
		this.deviceMat.depthWrite = false;
		this.deviceMat.transparent = true;
		this.deviceMat.map.needsUpdate = true;
		this.deviceMat.onBeforeCompile = shader => this.modifyShader(shader);

		//Create mesh
		const pos = this.manager.loader.getAsset("devices_edu");
		const count = this.countDevices(pos, json);
		this.geom = new THREE.PlaneBufferGeometry(1, 1);
		this.mesh = new THREE.InstancedMesh(this.geom, this.deviceMat, count);

		this.index = 0;
		this.uvs = [];
		this.instanceOpacity = [];
		let id, device;

		for (let i = 0; i < json.length; i++)
		{
			id = json[i].id;
			device = pos[id];

			//Check that devices.json contains required device
			if (device === undefined)
			{
				console.error("ERROR: Device " + id + " not set in devices_edu.json");
				continue;
			}

			//Create device mesh
			if (device["cart"]) this.createCartridge(id, device.cart, lookup[id]);
		}

		this.geom.setAttribute("iUvs", new THREE.InstancedBufferAttribute(new Float32Array(this.uvs), 4));
		this.geom.setAttribute("iOpacity", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceOpacity), 1));

		this.manager.add(this.mesh);
	}

	createCartridge (id, json, brand)
	{
		const cnt = this.manager.country;
		const d = new DeviceHelper(json, id, cnt, true);
		this.addDeviceToMesh(d);

		if (this.brandDevices[brand] === undefined)
			this.brandDevices[brand] = [];

		this.brandDevices[brand].push(d);
		this.allDevices.push(d);
	}

	addDeviceToMesh (d)
	{
		this.uvs.push(d.mtx.uvx);
		this.uvs.push(d.mtx.uvy);
		this.uvs.push(d.mtx.uvw);
		this.uvs.push(d.mtx.uvz);

		this.instanceOpacity.push(0);

		this.mesh.setMatrixAt(this.index, d.matrix);

		this.index++;
	}

	//Need to get the correct number of carts and models
	//Only count the devices that the json requires
	countDevices (available, requested)
	{
		let cnt = 0;

		for (let key in available)
		{
			if (!this.doesContain(key, requested)) continue;

			if (available[key].cart !== undefined) cnt++;
			if (available[key].device !== undefined) cnt++;
		}

		return cnt;
	}
	doesContain (key, array)
	{
		for (let i = 0; i < array.length; i++)
		{
			if (array[i].id === key) return true;
		}

		return false;
	}

	changeDevice (deviceObj)
	{
		this.currentBrand = deviceObj.brandId;

		//Hide everything
		this.hide(this.allDevices);

		//Show all devices for brandId
		this.show(this.brandDevices[deviceObj.brandId]);
	}

	show (deviceArray)
	{
		for (let i = 0; i < deviceArray.length; i++)
		{
			deviceArray[i].setAlpha(1);
		}
	}

	hide (deviceArray)
	{
		for (let i = 0; i < deviceArray.length; i++)
		{
			deviceArray[i].setAlpha(0);
		}
	}

	update ()
	{
		if (!this.mesh) return;

		let flagOpacityChange = false;
		let device;

		for (let i = 0; i < this.allDevices.length; i++)
		{
			device = this.allDevices[i];
			device.animate();

			//DEBUG
			// device.updateDebugRect(this.manager.camera.getCanvasSpaceRect(device.position, device.scale));

			if (device.opacityFlag)
			{
				this.instanceOpacity[i] = device.getAlpha();
				flagOpacityChange = true;
			}

			this.mesh.setMatrixAt(i, device.matrix);
		}

		//Update text opacities
		if (flagOpacityChange)
		{
			this.geom.setAttribute("iOpacity", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceOpacity), 1));
		}

		this.mesh.instanceMatrix.needsUpdate = true;
	}


	modifyShader (shader)
	{
		const commonVertexChunk = [
			'attribute vec4 iUvs;',
			'attribute float iOpacity;',
			'varying vec4 vUvs;',
			'varying float vOpacity;',
			'#include <common>'
		].join( '\n' );

		const beginVertexChunk = [
			'#include <begin_vertex>',
			'vUvs = iUvs;',
			'vOpacity = iOpacity;'
		].join( '\n' );


		const commonFragChunk = [
			'varying vec4 vUvs;',
			'varying float vOpacity;',
			'#include <common>'
		].join( '\n' );

		const diffuseFragChunk = [
			'vec4( diffuse, vOpacity );'
		].join( '\n' );

		const offsetFragChunk = [
			'vec2 newUV = vUv - vUvs.xy;',
			'newUV *= vUvs.zw;',
			'vec4 texelColor = texture2D( map, newUV );',
			'texelColor = mapTexelToLinear( texelColor );',
			'diffuseColor *= texelColor;'
		].join( '\n' );


		shader.vertexShader = shader.vertexShader
			.replace( '#include <common>', commonVertexChunk )
			.replace( '#include <begin_vertex>', beginVertexChunk );

		shader.fragmentShader = shader.fragmentShader
			.replace( '#include <common>', commonFragChunk )
			.replace( 'vec4( diffuse, opacity );', diffuseFragChunk )
			.replace( '#include <map_fragment>', offsetFragChunk);
	}
}
