import * as THREE from "three";
import Math2 from "./utils/math2";

export default class CameraController extends THREE.PerspectiveCamera
{
	constructor (canvas)
	{
		const fov = 35.9834;
		const ratio = canvas.width / canvas.height;
		const near = 0.1;
		const far = 8000;

		super(fov, ratio, near, far);

		this.target = new THREE.Vector3(0, 0, 1650);
		this.origin = this.target.clone();
		this.position.copy(this.target);
		this.lookAt(new THREE.Vector3(0, 0, 0));
		this.active = true;

		this.halfWide = canvas.width * 0.5;
		this.halfHigh = canvas.height * 0.5;
		this.raycaster = new THREE.Raycaster();

		this.aspect = ratio;
		this.updateProjectionMatrix();
	}

	resize (w, h, isLandscape)
	{
		this.aspect = w / h;
		this.updateProjectionMatrix();
		this.PORTRAIT = !isLandscape;

		//Save screen dimensions
		this.halfWide = w * 0.5;
		this.halfHigh = h * 0.5;

		if (this.PORTRAIT)
		{
			const radAng = this.fov * Math2.deg2rad;
			const radFov = 2 * Math.atan(Math.tan(radAng / 2.0) * this.aspect);
			this.fovh = Math2.rad2deg * radFov;
		}

		//Update start position
		this.target = new THREE.Vector3();
		this.target.z = h * 0.5 / Math.tan(this.fov * 0.5 * Math2.deg2rad);
		this.origin = this.target.clone();
		this.position.copy(this.target);
	}

	getCanvasSpaceRect (pos, scale)
	{
		const l = pos.x - (scale.x * 0.5);
		const r = pos.x + (scale.x * 0.5);
		const t = pos.y + (scale.y * 0.5);
		const b = pos.y - (scale.y * 0.5);

		const rect = {};
		rect.topLeft = this.getCanvasSpacePosition(l, t, pos.z);
		rect.btmRight = this.getCanvasSpacePosition(r, b, pos.z);
		rect.left = rect.topLeft.x;
		rect.right = rect.btmRight.x;
		rect.top = rect.topLeft.y;
		rect.bottom = rect.btmRight.y;
		rect.width = rect.right - rect.left;
		rect.height = rect.bottom - rect.top;

		return rect;
	}

	getCanvasSpacePosition (x, y, z)
	{
		let screenPos = new THREE.Vector3(x, y, z);
		screenPos.project(this);
		screenPos.x =  (screenPos.x * this.halfWide) + this.halfWide;
		screenPos.y = -(screenPos.y * this.halfHigh) + this.halfHigh;

		return screenPos;
	}

	getWorldSpacePosition (pos, dist)
	{
		const coords = new THREE.Vector3(
			(pos.x / (this.halfWide + this.halfWide)) * 2 - 1,
			-(pos.y / (this.halfHigh + this.halfHigh)) * 2 + 1,
			0.5
		);

		const worldPosition = new THREE.Vector3();
		this.raycaster.setFromCamera(coords, this);
		this.raycaster.ray.at(dist, worldPosition);

		return worldPosition;
	}

	update ()
	{
		if (!this.active) return;

		if (this.position.distanceToSquared(this.target) > 1)
		{
			this.position.lerp(this.target, 0.1667);
		}
	}

	setTarget (mtx)
	{
		const pos = new THREE.Vector3();
		const rot = new THREE.Quaternion();
		const scl = new THREE.Vector3();

		mtx.decompose(pos, rot, scl);

		const layout = this.calculateScreenSpace();

		let camFov = this.fov;
		if (this.PORTRAIT) camFov = this.fovh;

		const fTarget = (100 * scl.x) / (layout.size * 0.9);
		const dist = (fTarget * 0.5) / Math.tan(camFov * 0.5 * Math2.deg2rad);
		const fHeight = 2.0 * dist * Math.tan(camFov * 0.5 * Math2.deg2rad);

		this.target.x = pos.x;
		this.target.y = pos.y + (fHeight * layout.offY);
		this.target.z = pos.z + dist;
	}

	calculateScreenSpace ()
	{
		const root = document.querySelector("#bat_UI");
		const win = root.getBoundingClientRect();

		let btm = win.bottom - 70; //70 is value suggested by Rich's designs
		const filter = root.getElementsByClassName("filterMenu");
		if (filter.length !== 0)
		{
			if (filter[0].style.visibility !== "hidden")
				btm = filter[0].getBoundingClientRect().top;
		}

		let top = win.top + 100; //100 is enough room for the semi-circle button
		const home = root.getElementsByClassName("btn_home");
		if (home.length !== 0) top = home[0].getBoundingClientRect().bottom;

		const obj = {};
		obj.size = (btm - top) / win.height;
		obj.offY = top + ((btm - top) * 0.5) - win.top;
		obj.offY /= win.height;
		obj.offY -= 0.5;

		return obj;
	}

	reset ()
	{
		this.target = this.origin.clone();
		this.position.copy(this.target);
	}
}
