/*
 *  Asset loader
 *  Takes a json string of assets and loads them
 */

import * as THREE from "three";
import JsonLoader from "./utils/json_loader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
// import { Zlib } from "three/examples/jsm/libs/inflate.module.min.js";
import WebFont from "webfontloader";
import mapLimit from "map-limit";

const cb = () => {};

export default class AssetLoader {
	constructor(jsonQueue) {
		this.cache = {};
		this.queue = [];

		this.isQueue = true;
		this.gltfLoader = new GLTFLoader();
		this.fbxLoader = new FBXLoader();
		this.texLoader = new THREE.TextureLoader();

		// JsonLoader.load(jsonQueue, (result) => this.parseAssetsList(result));

		this.parseAssetsList(jsonQueue);
	}

	addToQueue(id, uri, type) {
		const obj = {};
		obj.id = id;
		obj.uri = uri;
		obj.type = type;

		this.queue.push(obj);
	}

	parseAssetsList(json) {
		for (let i = 0; i < json.length; i++) {
			this.queue.push(json[i]);
		}

		this.isQueue = false;
	}

	//add validation/warnings
	addAsset(id, object) {
		this.cache[id] = object;
	}

	//add validation/warnings
	getAsset(id) {
		return this.cache[id];
	}

	load(callback) {
		if (this.isQueue) {
			window.setTimeout(() => this.load(callback), 100);
			return;
		}

		//Copy queue and clear original
		const q = this.queue.slice();
		this.queue.length = 0;

		let count = 0;
		const total = q.length;
		if (total === 0) {
			callback();
			return;
		}

		console.log("Loading " + total + " items...");

		mapLimit(
			q,
			10,
			(item, next) => {
				this.loadItem(item, (err, result) => {
					if (err) {
						console.error("Error loading asset: " + item.uri);
					}

					count++;
					next(null, result);
				});
			},
			callback
		);
	}

	loadItem(item, callback = cb) {
		const cache = this.cache;

		const done = (data, err) => {
			if (err) delete cache[item.id];
			else cache[item.id] = data;

			callback(err, data);
		};

		switch (item.type) {
			case "data":
				done(item.data);
				break;

			case "json":
				JsonLoader.load(
					item.uri,
					(result) => done(result),
					null,
					(error) => done(null, error)
				);
				break;

			case "gltf":
				this.gltfLoader.load(
					item.uri,
					(result) => done(result),
					null,
					(error) => done(null, error)
				);
				break;

			case "fbx":
				this.fbxLoader.load(
					item.uri,
					(result) => done(result),
					null,
					(error) => done(null, error)
				);
				break;

			case "tex":
				this.texLoader.load(
					item.uri,
					(result) => done(result),
					null,
					(error) => done(null, error)
				);
				break;

			case "font":
				WebFont.load({
					custom: {
						families: [item.id]
					},
					active: function () {
						//Font preloading is very temperamental
						//This hack ensures required fonts are loaded
						const hack = document.createElement("div");
						hack.innerHTML = "&nbsp;";
						hack.style.fontFamily = item.id;
						hack.style.fontWeight = item.weight;
						if (item.style !== undefined) hack.style.fontStyle = item.style;
						document.body.appendChild(hack);

						window.setTimeout(() => {
							document.body.removeChild(hack);
							done();
						}, 500);
					}
				});
				break;

			default:
				console.warn("Asset " + item.uri + " not loaded");
				done();
				break;
		}
	}
}
