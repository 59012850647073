/*
 *  Flavours View
 *  Controls everything related to the flavour discs
 */

import FlavourDiscs from "./disc_menu";
import FilterMenu from "./filter_menu";
import HomeButton from "./home_btn";
import Carousel from "./carousel";

export default class FlavoursView
{
	constructor (manager, isEdu = false)
	{
		this.stateName = "FLAVOURS_VIEW";
		this.isEdu = isEdu;
		this.hidden = isEdu;

		this.discMenu = new FlavourDiscs(manager);
		this.filters = new FilterMenu(manager);
		this.carousel = new Carousel(manager);
		if (!this.isEdu) this.homeBtn = new HomeButton(manager);
		this.camera = manager.camera;

		//Register with state manager
		manager.state.registerState(this.stateName);
		manager.state.addStateChangeListener((to, from) => this.changeState(to, from));
		manager.state.addEventListener("changeDevice", (id) => this.changeDevice(id));
	}

	init (json, devicesJson, positions, mesh)
	{
		this.devicesJson = devicesJson;

		this.discMenu.init(devicesJson, positions, mesh, json.pages.flavour_detail);
		if (json.filters !== undefined) this.filters.init(json.filters, json.pages.landing.filter);
		this.carousel.init(this.brandDevices, devicesJson);
		if (!this.isEdu) this.homeBtn.init();
	}

	changeState (to, from)
	{
		if (to == this.stateName) this.show();
		else this.hide();
	}

	changeDevice (deviceObj)
	{
		//Check if filters menu should be visible
		for (let i = 0; i < this.devicesJson.length; i++)
		{
			if (this.devicesJson[i].id == deviceObj.deviceId)
			{
				this.checkForFilters(this.devicesJson[i]);
				return;
			}
		}
	}

	checkForFilters(json)
	{
		const check = json.flavours[0];
		const hasFilters = check.hasOwnProperty("filters");

		this.filters.setVisible(hasFilters);
	}

	show ()
	{
		this.discMenu.show();
		this.filters.show();
		this.carousel.show();
		if (!this.isEdu && !this.hidden) this.homeBtn.show();
	}

	hide ()
	{
		this.discMenu.hide();
		this.filters.hide();
		this.carousel.hide();
		if (!this.isEdu && !this.hidden) this.homeBtn.hide();

		this.camera.reset();
	}

	showHome ()
	{
		this.hidden = false;
		this.homeBtn.show();
	}

	hideHome ()
	{
		this.hidden = true;
		this.homeBtn.hide();
	}
}
