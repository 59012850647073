import * as THREE from "three"; //https://unpkg.com/three@0.117.1/build/three.module.js
import CameraController from "./camera";
import { environment } from "../json/assets";

export default class SceneManager
{
	constructor (canvas)
	{
		this.canvas = canvas;
		this.update = [];
		this.resize = [];
		this.events = [];

		this.scene = this.createScene();
		this.renderer = this.createRenderer();
		this.camera = new CameraController(canvas);
		this.lights = this.createLight();

		this.addToUpdate(() => this.camera.update(), "cam");
	}

	//private
	createScene ()
	{
		const s = new THREE.Scene();
		// s.background = new THREE.Color(0x00ffff);
		s.fog = new THREE.Fog(new THREE.Color(0x000000), 500, 8000);

		//Create & load environment map
		const loader = new THREE.CubeTextureLoader();
		s.environment = loader.load(environment);

		return s;
	}

	createRenderer ()
	{
		const r = new THREE.WebGLRenderer({ canvas: this.canvas, alpha: true });

		r.setSize(this.canvas.width, this.canvas.height);
		r.setPixelRatio(window.devicePixelRatio);

		r.powerPreference = "high-performance";

		return r;
	}

	createLight ()
	{
		const dl = new THREE.DirectionalLight(0xffffff, 1.0);
		dl.position.set(0, 0, 1);

		this.scene.add(dl);

		return dl;
	}

	onUpdate ()
	{
		//Update any objects that have requested it
		for (let i = 0; i < this.update.length; i++)
		{
			this.update[i].func();
		}

		this.renderer.render(this.scene, this.camera);
	}

	onResize (w, h)
	{
		if (w > h) this.landscape = true;
		else this.landscape = false;

		//Update camera
		this.camera.resize(w, h, this.landscape);
		this.renderer.setSize(w, h);
		this.renderer.render(this.scene, this.camera);

		//Adjust fog
		this.scene.fog.near = this.camera.position.z / 3.0;
		this.scene.fog.far = this.camera.position.z * 5;

		//Update any objects that have requested it
		for (let i = 0; i < this.resize.length; i++)
		{
			this.resize[i].func(w, h);
		}
	}

	bindEvents ()
	{
		//Update any objects that have requested it
		for (let i = 0; i < this.events.length; i++)
		{
			this.events[i].func();
		}
	}

	addToUpdate (func, id)
	{
		this.update.push({ func, id });
	}

	removeFromUpdate (id)
	{
		for (let i = 0; i < this.update.length; i++)
		{
			if (this.update[i].id === id)
			{
				this.update.splice(i, 1);
				break;
			}
		}
	}

	addToResize (func, id)
	{
		this.resize.push({ func, id });
	}

	removeFromResize (id) {
		for (let i = 0; i < this.resize.length; i++)
		{
			if (this.resize[i].id === id)
			{
				this.resize.splice(i, 1);
				break;
			}
		}
	}

	addToEvents (func, id)
	{
		this.events.push({ func, id });
	}

	removeFromEvents (id)
	{
		for (let i = 0; i < this.events.length; i++)
		{
			if (this.events[i].id === id)
			{
				this.events.splice(i, 1);
				break;
			}
		}
	}

	//Add object to scene
	add (object)
	{
		this.scene.add(object);
	}

	remove (object)
	{
		this.scene.remove(object);
	}
}
