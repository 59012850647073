export default class Math2
{
	constructor () {}

	static randomRange (min, max)
	{
		if (max === undefined)
		{
			max = min;
			min = 0;
		}

		return Math.random() * (max - min) + min;
	}

	static randomInt (min, max)
	{
		return Math.floor(this.range(min, max));
	}


	static get deg2rad ()
	{
		if (!this.deg2Rad) this.deg2Rad = Math.PI / 180;

		return this.deg2Rad;
	}

	static get rad2deg ()
	{
		if (!this.rad2Deg) this.rad2Deg = 180 / Math.PI;

		return this.rad2Deg;
	}
}
