/*
 *  BAT Education Tablet
 *  This is the root module that creates the education tablet
 */

import SceneManager from "./manager";
import StateManager from "./state_manager";
import AssetLoader from "./preloader";

import DevicesEdu from "./devices_edu";
import FlavoursView from "./flavours_view";

import Console from "./utils/console";
import Stats from "./utils/stats";

import assets from "../json/assets.js";

export default class EduTablet
{
	constructor (debug = false)
	{
		if (debug)
		{
			this.stats = new Stats();
			this.logger = new Console();
			this.DEBUG = true;
		}

		//Find canvas
		this.canvas = document.querySelector("canvas");

		//Create three.js scene manager
		this.manager = new SceneManager(this.canvas);

		//Create preloader
		this.manager.loader = new AssetLoader(assets);

		//Create event manager
		this.manager.state = new StateManager();
		this.manager.states = this.manager.state.states;

		//Create defaults
		this.brands = ["vype", "glo", "lyft", "epok", "velo", "vuse"];
		this.currentBrand = "vuse";
		this.brandData = {};
		this.devicesJson = [];
		this.brandLookup = {};

		//Get root html element
		this.root = document.querySelector("#bat_UI");
		this.canvas.classList.add(this.currentBrand);
		this.root.classList.add(this.currentBrand);

		//Create view
		this.devicesEdu = new DevicesEdu(this.manager);
		this.flavours = new FlavoursView(this.manager, true);

		//Properties
		this.isLoaded = false;
		this.isActive = false;

		this.bindEvents();
		this.resize();
	}

	init (json)
	{
		return new Promise((resolve, reject) => {
			if (typeof json === "string") json = JSON.parse(json);

			if (!json)
				this.manager.loader.addToQueue(
					"content",
					"./json/de_edu_stripped.json",
					"json"
				);
			else this.manager.loader.addAsset("content", json);

			this.manager.loader.load(() => this.initApp(resolve));
		});
	}

	initApp (resolve)
	{
		const json = this.manager.loader.getAsset("content");
		const disc = this.manager.loader.getAsset("disc");
		const posi = this.manager.loader.getAsset("discs_pos");

		//Set country code
		this.manager.country = json.market_details.country;
		this.canvas.classList.add("country_" + this.manager.country);
		this.root.classList.add("country_" + this.manager.country);

		this.createBrandData(json);

		//Initialise states
		this.devicesEdu.init(this.devicesJson, this.brandLookup);
		this.flavours.brandDevices = this.devicesEdu.brandDevices;
		this.flavours.init(json, this.devicesJson, posi, disc);

		this.isLoaded = true;

		console.info("EduTablet v1.6.2");

		resolve();

		if (this.DEBUG) this.go_to_product("epen3");
		if (this.DEBUG) this.createDebugMenu();
	}

	go_to_product (deviceId)
	{
		const brandId = this.brandLookup[deviceId];
		if (brandId === undefined)
		{
			console.error("ERROR: Product " + deviceId + " doesn't exist in provided json");
			return;
		}
		console.log("Changing brand to " + brandId);

		//Change html classes
		this.canvas.classList.remove(this.currentBrand);
		this.root.classList.remove(this.currentBrand);
		this.canvas.classList.add(brandId);
		this.root.classList.add(brandId);
		this.currentBrand = brandId;

		//Send device change event
		this.manager.state.publishEvent("changeDevice", {"deviceId": deviceId, "brandId": brandId});
		this.manager.state.publishEvent("analytics", {"event": "product", "data": deviceId});

		this.start();
	}

	//Fires callback(deviceId) when device is changed
	registerProductCallback (callback)
	{
		this.manager.state.addEventListener("changeDevice", (obj) => callback(obj.deviceId));
		this.manager.state.addEventListener("nextDevice", callback);
		this.manager.state.addEventListener("prevDevice", callback);
	}

	//Fires callback(analyticsObj) on certain interactions
	//analyticsObj = {"event":"string", "data":"string"}
	registerAnalyticsEvent (callback)
	{
		this.manager.state.addEventListener("analytics", callback);
	}

	start ()
	{
		console.log("Launching app...");

		this.manager.state.changeState(this.manager.states.FLAVOURS_VIEW);
		this.stopRender();
		this.render();

		this.isActive = true;
	}

	stop ()
	{
		this.stopRender();
	}

	//If you want to add Stats outside of DEBUG mode, use this
	addStats ()
	{
		this.stats = new Stats();
	}

	//If you want to add Stats outside of DEBUG mode, use this
	addConsole ()
	{
		this.console = new Console();
	}

	bindEvents ()
	{
		this.resize = this.resize.bind(this);

		removeEventListener("resize", this.resize);
		removeEventListener("orientationchange", this.resize);

		addEventListener("resize", this.resize);
		addEventListener("orientationchange", this.resize);

		this.manager.bindEvents();
	}

	resize ()
	{
		let w = this.canvas.parentElement.offsetWidth;
		let h = this.canvas.parentElement.offsetHeight;
		if (w === undefined) w = innerWidth;
		if (h === undefined) h = innerHeight;
		if (this.DEBUG) w = innerWidth;
		if (this.DEBUG) h = innerHeight;

		this.canvas.width = w;
		this.canvas.height = h;

		this.manager.onResize(w, h);
	}

	render ()
	{
		if (this.isActive) this.manager.onUpdate();
		if (this.stats) this.stats.render(this.manager.renderer);

		this.animId = requestAnimationFrame(() => this.render());
	}

	stopRender ()
	{
		if (this.animId) cancelAnimationFrame(this.animId);
		this.animId = undefined;

		this.isActive = false;
	}


	//Create a lookup for brands/devices
	createBrandData (json)
	{
		let brand, devices, id;

		for (let b = 0; b < this.brands.length; b++)
		{
			brand = json[this.brands[b]];
			if (brand === undefined) continue;
			devices = brand[0].devices;

			for (let d = 0; d < devices.length; d++)
			{
				id = devices[d].id;
				this.brandLookup[id] = brand[0].id;

				//add device json to collection
				this.devicesJson.push(devices[d]);
				this.brandData[brand] = devices[d];
			}
		}
	}



	//For debug purposes
	createDebugMenu ()
	{
		const btns = document.createElement("div");
		btns.style.position = "absolute";
		btns.style.padding = "20px";
		btns.style.bottom = "0px";
		btns.style.right = "0px";
		btns.style.border = "1px solid red";
		this.root.appendChild(btns);

		const btn1 = document.createElement("div");
		btn1.addEventListener("mousedown", evt => this.onDebugTouch(evt), false);
		btn1.innerHTML = "epod";
		btn1.name = "epod";
		btn1.style.color = "#fff";
		btn1.style.padding = "20px";
		btns.appendChild(btn1);

		const btn2 = document.createElement("div");
		btn2.addEventListener("mousedown", evt => this.onDebugTouch(evt), false);
		btn2.innerHTML = "go";
		btn2.name = "go";
		btn2.style.color = "#fff";
		btn2.style.padding = "20px";
		btns.appendChild(btn2);

		const btn3 = document.createElement("div");
		btn3.addEventListener("mousedown", evt => this.onDebugTouch(evt), false);
		btn3.innerHTML = "velo_pouch";
		btn3.name = "velo_pouch";
		btn3.style.color = "#fff";
		btn3.style.padding = "20px";
		btns.appendChild(btn3);

		const btn4 = document.createElement("div");
		btn4.addEventListener("mousedown", evt => this.onDebugTouch(evt), false);
		btn4.innerHTML = "glo_hyper";
		btn4.name = "glo_hyper";
		btn4.style.color = "#fff";
		btn4.style.padding = "20px";
		btns.appendChild(btn4);

		const btn5 = document.createElement("div");
		btn5.addEventListener("mousedown", evt => this.onDebugTouch(evt), false);
		btn5.innerHTML = "epok";
		btn5.name = "epok";
		btn5.style.color = "#fff";
		btn5.style.padding = "20px";
		btns.appendChild(btn5);

	}

	onDebugTouch (evt)
	{
		evt.stopPropagation();
		this.go_to_product(evt.target.name);
	}
}
