/*
 *  Disc
 *  Use this as an object to manage main disc positions & movement
 */

import * as THREE from "three";
import gsap from "gsap";
import Math2 from "./utils/math2"

export default class Disc extends THREE.Object3D
{
	constructor (id, json)
	{
		super();

		this.discId = id;
		this.name = json.name;
		this.bgCol = json.color_flavour;
		this.bgImg = json.background_image;
		this.bgAlpha = json.alpha_front;
		this.txtAlpha = json.text_front;
		this.json = json;

		if (json.filters === undefined) this.filters = [];
		else this.filters = json.filters.flavours;

		this.startPos = new THREE.Vector3();
		this.startRot = new THREE.Vector3().random();
		this.targetPos = new THREE.Vector3(-2000, 0, 0);

		this.position.set(-2000, 0, 0);
		this.scale.set(1, 1, 1);

		this.animFlag = true; //TODO

		// //Create a random start rotation
		// this.startRot.applyAxisAngle();

		this.mainColor = new THREE.Color();
		this.discColor = json.color_flavour;
		this.mainColor.set(this.discColor);
		this.colorFlag = true;
		this.grayMix = 0.0;
		this.mixFlag = true;

		this.textOpacity = 1.0;
		this.opacityFlag = true;

		this.updateMatrix();
	}

	activate (deviceId)
	{
		this.deviceId = deviceId;
		this.active = true;
	}

	reset ()
	{
		gsap.killTweensOf(this.position);
		this.position.copy(this.startPos);
		this.updateMatrix();

		this.textOpacity = 1;
		this.opacityFlag = true;

		this.active = false;
	}

	setTarget (json)
	{
		this.targetPos.set(json.pos[0], json.pos[1], -json.pos[2]);
		this.startPos.copy(this.targetPos);
		this.startPos.z += 3500;

		this.position.copy(this.startPos);
		this.scale.set(json.scale, json.scale, json.scale);
		this.scale.multiplyScalar(2);

		this.rotateOnAxis(this.startRot, Math.PI);
		this.updateMatrix();
	}

	setFiltered (isHighlighted)
	{
		let mix = 1.0;

		//Set mix to 0
		if (isHighlighted) mix = 0.0;

		//If mix value is already correct, return
		if (mix === this.grayMix) return;

		gsap.to(this, {
			duration: 1,
			grayMix: mix,
			onUpdate: () =>
			{
				this.mixFlag = true;
			}
		});
	}

	//No longer using colours
	// setFiltered (isHighlighted)
	// {
	// 	let col;
	//
	// 	//Set target color
	// 	if (isHighlighted) col = this.discColor;
	// 	else col = this.grayColor;
	//
	// 	//If disc color is already correct, return
	// 	this.tempColor = "#" + this.mainColor.getHexString();
	// 	if (col === "#" + this.tempColor) return;
	//
	// 	gsap.to(this, {
	// 		duration: 1,
	// 		tempColor: col,
	// 		onUpdate: () =>
	// 		{
	// 			this.mainColor.set(this.tempColor);
	// 			this.colorFlag = true;
	// 		}
	// 	});
	// }

	open ()
	{
		gsap.to(this, {
			duration: 1,
			textOpacity: 0,
			onUpdate: () =>
			{
				this.opacityFlag = true;
			}
		});
	}

	flip ()
	{
		gsap.killTweensOf(this.rotation);
		gsap.to(this.rotation, {
			duration: 1,
			y: -Math.PI
		});
	}

	unflip ()
	{
		gsap.killTweensOf(this.rotation);
		gsap.to(this.rotation, {
			duration: 1,
			y: 0
		});
	}

	close ()
	{
		gsap.to(this, {
			duration: 1,
			textOpacity: 1,
			onUpdate: () =>
			{
				this.opacityFlag = true;
			}
		});

		this.unflip();
	}

	getTextOpacity ()
	{
		this.opacityFlag = false;
		return this.textOpacity;
	}

	getRed ()
	{
		this.colorFlag = false;
		return this.mainColor.r;
	}

	getGreen ()
	{
		this.colorFlag = false;
		return this.mainColor.g;
	}

	getBlue ()
	{
		this.colorFlag = false;
		return this.mainColor.b;
	}

	show (delay, direction = 0)
	{
		// this.activate();
		if (!this.active) console.warn("Disc must be activated before showing");

		let ease = "";
		let duration = 2;

		if (direction === 0)
		{
			this.position.copy(this.startPos);
			this.rotateOnAxis(this.startRot, Math.PI);
			this.updateMatrix();

			ease = "elastic.out(1.2, 0.75)";
			delay += 1;
		}
		else
		{
			this.position.x = this.targetPos.x + (3200 * direction);
			this.position.y = this.targetPos.y;
			this.position.z = 0;
			this.rotation.set(0, 0, 0);
			this.updateMatrix();

			ease = "back.out(1.0)";
			duration = 1.6;
			// ease = "elastic.out(1.0, 1.0)";
		}

		gsap.killTweensOf(this.position);
		gsap.to(this.position, {
			duration: duration,
			delay: delay,
			ease: ease,
			x: this.targetPos.x,
			y: this.targetPos.y,
			z: this.targetPos.z
		});

		gsap.killTweensOf(this.rotation);
		gsap.to(this.rotation, {
			duration: duration,
			delay: delay,
			ease: ease,
			x: 0,
			y: 0,
			z: 0
		});
	}

	hide (direction = 0)
	{
		let xx, yy, zz;

		if (direction === 0)
		{
			xx = this.startPos.x;
			yy = this.startPos.y;
			zz = this.startPos.z;
		}
		else
		{
			xx = this.targetPos.x + (3200 * direction);
			yy = this.targetPos.y;
			zz = 0;
		}

		gsap.killTweensOf(this.position);
		gsap.to(this.position, {
			duration: 1,
			x: xx,
			y: yy,
			z: zz,
			onComplete: () => {
				this.reset();
			}
		});
	}

	animate ()
	{
		if (!this.active) return;

		this.updateMatrix();
	}
}
