import { WebGLRenderer } from "three";

export default class Stats
{
	constructor ()
	{
		this.domElement = document.createElement("pre");
		this.domElement.style.cssText  = "background:#000;border:1px solid #999;border-radius:10px;width:100px;text-align:left;padding:10px;opacity:0.7;overflow:hidden;color:#fff;font-size:9px;line-height:15px";
		this.domElement.style.position = "absolute";
		this.domElement.style.left     = "10px";
		this.domElement.style.top      = "10px";
		document.body.appendChild(this.domElement);

		this.text = [];
		this.lines = 17;

		for (let i = 0; i < this.lines; i++)
		{
			this.text[i] = document.createElement("div");
			this.text[i].innerHTML = "-";

			this.domElement.appendChild(this.text[i]);
		}

		this.beginTime = ( performance || Date ).now();
		this.prevTime = this.beginTime;
		this.lastTime = this.beginTime;
		this.frames = 0;
		this.mem = "N/A";

		this.showMem = self.performance && self.performance.memory;
	}

	calculate ()
	{
		this.frames++;
		const time = ( performance || Date ).now();

		this.ms = (time - this.beginTime); //MAX: 200

		if (time >= this.prevTime + 1000)
		{
			this.fps = (this.frames * 1000) / (time - this.prevTime); //MAX: 100

			this.prevTime = time;
			this.frames = 0;

			if (this.showMem)
			{
				const mem = performance.memory;
				this.mem = mem.usedJSHeapSize / 1048576; //MAX: mem.jsHeapSizeLimit / 1048576;
			}
		}

		return time;
	}

	render (webGLRenderer)
	{
		console.assert(webGLRenderer instanceof WebGLRenderer);

		this.beginTime = this.calculate();

		//update at 30fps max
		if (this.beginTime < this.lastTime + (1000/30)) return;
		this.lastTime = this.beginTime;

		let i = 0;
		this.text[i++].textContent = "===== Frames =====";
		this.text[i++].textContent = Math.round(this.fps) + " FPS";// (" + "" + ")";
		this.text[i++].textContent = Math.round(this.ms) + " MS";// (" + "" + ")";
		this.text[i++].textContent = "Frame: "      + webGLRenderer.info.render.frame;

		this.text[i++].textContent = "===== Memory =====";
		this.text[i++].textContent = "Geometries: " + webGLRenderer.info.memory.geometries;
		this.text[i++].textContent = "Textures: "   + webGLRenderer.info.memory.textures;
		this.text[i++].textContent = "Allocation: " + this.mem;

		this.text[i++].textContent = "===== Render =====";
		this.text[i++].textContent = "Draw Calls: " + webGLRenderer.info.render.calls;
		this.text[i++].textContent = "Triangles: "  + webGLRenderer.info.render.triangles;
		this.text[i++].textContent = "Points: "     + webGLRenderer.info.render.points;
		this.text[i++].textContent = "Lines: "      + webGLRenderer.info.render.lines;

		this.text[i++].textContent = "===== Screen =====";
		this.text[i++].textContent = "Res: " + window.innerWidth + " x " + window.innerHeight;
		this.text[i++].textContent = "Aspect Ratio: " + this.getAspect();
		this.text[i++].textContent = "Pixel Ratio: " + window.devicePixelRatio;
	}

	getAspect ()
	{
		const w = window.innerWidth;
		const h = window.innerHeight;

		if (w / h * 10 == 16) return "16:10";
		if (w / h * 9  == 16) return "16:9";
		if (w / h * 16 == 10) return "10:16";
		if (w / h * 16 == 9)  return "9:16";

		// if (w / h * 5 == 8) return "8:5";
		// if (w / h * 5 == 6) return "6:5";
		// if (w / h * 4 == 5) return "5:4";
		// if (w / h * 3 == 4) return "4:3";

		return Math.round(window.innerWidth / window.innerHeight * 100) / 100;
	}
}
