/*
 *  Carousel
 *  Animate device model carousel
 */

import * as THREE from "three";
import gsap from "gsap";

export default class Carousel
{
	constructor (manager)
	{
		this.stateMngr = manager.state;
		this.camera = manager.camera;
		this.manager = manager;
		this.helper = new THREE.Object3D();

		this.enabled = false;
		this.active = false;
		this.prevActive = false;
		this.nextActive = false;

		this.currentBrandId = "vype";
		this.currentDeviceId = "";
		this.currentDevice = -1;
		this.deviceCount = 0;

		this.isOpen = false;

		//Register for events
		manager.addToResize(() => this.positionTargets(), "carousel");

		//Register for device changes
		manager.state.addEventListener("changeDevice", (id) => this.changeDevice(id));

		//Register disc touch events
		manager.state.addEventListener("openDisc", (data) => this.openDisc(data));
		manager.state.addEventListener("closeDisc", (data) => this.closeDisc(data));
	}

	init (brandDevices, devicesJson)
	{
		this.brandDevices = brandDevices;
		this.json = devicesJson;
		this.createHtmlElements();
	}

	changeDevice (deviceObj)
	{
		this.currentBrandId = deviceObj.brandId;
		this.currentDeviceId = deviceObj.deviceId;

		//Get devices in current brand
		this.devices = this.brandDevices[this.currentBrandId];
		this.deviceCount = this.devices.length;

		//Get device index
		this.currentDevice = this.getDeviceIndexByName(this.currentDeviceId);

		this.changeDeviceTitle();
		this.move(0);
	}

	openDisc ()
	{
		//Move devices behind camera
		for (let i = 0; i < this.deviceCount; i++)
		{
			this.devices[i].shiftForwards();
			//TODO: Fade out currentDevice

		}

		this.hide();
		this.isOpen = true;
	}

	closeDisc ()
	{
		this.active = true;

		this.toggleArrows();

		//Move devices back to correct position
		for (let i = 0; i < this.deviceCount; i++)
		{
			if (this.isOpen) this.devices[i].shiftBackwards();
		}

		gsap.to([this.title, this.subtitle], {
			duration: 1,
			autoAlpha: 1,
			onComplete: () =>
			{
				this.enabled = true;
				this.isOpen = false;
			}
		});
	}

	show ()
	{
		this.active = true;

		gsap.delayedCall(2, () => {
			this.enabled = true;
		});
	}

	hide ()
	{
		this.enabled = false;

		gsap.killTweensOf([this.btnPrev, this.btnNext, this.title, this.subtitle]);
		gsap.to([this.btnPrev, this.btnNext, this.title, this.subtitle], {
			duration: 0.3,
			autoAlpha: 0,
			onComplete: () =>
			{
				this.active = false;
			}
		});
	}

	move (dir)
	{
		let index, targ, pos, rot, rw, rh, s;
		let scl = new THREE.Vector3();
		let slots = [];
		this.enabled = false;

		for (let i = 0; i < this.deviceCount; i++)
		{
			index = i - this.currentDevice + 1;
			index = index < 0 ? index + this.deviceCount : index;
			index = index % Math.max(this.deviceCount, 3);

			//Get position
			targ = this.targets[index];
			pos = targ.world.pos;

			//Make sure off-screen direction is correct
			if (this.deviceCount > 3 && index === 3 && dir === -1)
			{
				targ = this.targets[4];
				pos = targ.world.pos;
			}

			//Get rotation (based on original camera position)
			this.helper.position.copy(pos);
			this.helper.lookAt(this.camera.origin);
			rot = this.helper.rotation.clone();

			//Fit device in target rect
			rw = targ.world.width / this.devices[i].mtx.sx;
			rh = targ.world.height / this.devices[i].mtx.sy;
			s = Math.min(rw, rh);

			//Set scale
			scl.set(s, s, s);
			scl.x *= this.devices[i].mtx.sx;
			scl.y *= this.devices[i].mtx.sy;
			scl.z *= this.devices[i].mtx.sz;

			//Set slot name
			slots[index] = this.devices[i].name;

			//Adjust position of off-screen devices
			if (this.deviceCount > 3 && index == 0 && dir == 1)
			{
				this.devices[i].position.copy(this.targets[4].world.pos);
			}
			if (this.deviceCount > 3 && index == 2 && dir == -1)
			{
				this.devices[i].position.copy(this.targets[3].world.pos);
			}

			gsap.to(this.devices[i].position, {
				duration: 0.8,
				x: pos.x,
				y: pos.y,
				z: pos.z,
				onComplete: () => {
					this.enabled = true;
				}
			});

			gsap.to(this.devices[i].scale, {
				duration: 0.8,
				x: scl.x,
				y: scl.y,
				z: scl.z
			});

			gsap.to(this.devices[i].rotation, {
				duration: 0.8,
				x: rot.x,
				y: rot.y,
				z: rot.z
			});
		}

		this.toggleArrows(slots);
	}

	changeDeviceTitle ()
	{
		gsap.to(this.title, {
			duration: 0.4,
			autoAlpha: 0
		});

		gsap.to(this.subtitle, {
			duration: 0.4,
			autoAlpha: 0,
			onComplete: () =>
			{
				this.showDeviceTitle();
			}
		});
	}

	showDeviceTitle ()
	{
		//Update text
		const d = this.getDeviceJsonByName(this.currentDeviceId);
		this.title.innerHTML = d.name.replace("\n", "<br>");
		this.subtitle.innerHTML = d.action.replace("\n", "<br>");

		//Reset positions
		this.title.style.marginTop = "0px";
		this.subtitle.style.marginTop = "0px";

		let diff = 0;
		let tt = this.title.getBoundingClientRect();
		let st = this.subtitle.getBoundingClientRect();
		if (tt.bottom > st.top) diff = tt.bottom - st.top;

		//Position text
		if (this.devices[this.currentDevice].hidden)
		{
			this.title.style.marginTop = "-180px";
			this.subtitle.style.marginTop = (diff - 180) + "px";
		}
		else
		{
			this.title.style.marginTop = "0px";
			this.subtitle.style.marginTop = diff + "px";
		}

		gsap.killTweensOf(this.title);
		gsap.killTweensOf(this.subtitle);

		gsap.to(this.title, {
			duration: 0.4,
			autoAlpha: 1,
			delay: 0.4
		});

		gsap.to(this.subtitle, {
			duration: 0.4,
			autoAlpha: 1,
			delay: 0.6
		});
	}

	toggleArrows (slots)
	{
		if (slots !== undefined)
		{
			this.prevActive = slots[0] !== undefined;
			this.nextActive = slots[2] !== undefined;
		}

		//Show/Hide arrows
		if (this.prevActive) this.showObj(this.btnPrev);
		else this.hideObj(this.btnPrev);

		if (this.nextActive) this.showObj(this.btnNext);
		else this.hideObj(this.btnNext);
	}

	hideObj (obj)
	{
		gsap.killTweensOf(obj);
		gsap.to(obj, {
			duration: 1,
			autoAlpha: 0
		});
	}
	showObj (obj)
	{
		gsap.killTweensOf(obj);
		gsap.to(obj, {
			delay: 1,
			duration: 1,
			autoAlpha: 1
		});
	}

	getDeviceIndexByName (deviceId)
	{
		for (let i = 0; i < this.deviceCount; i++)
		{
			if (this.devices[i].name === deviceId)
			{
				return i;
			}
		}

		console.error("ERROR: Device " + deviceObj.deviceId + " not found in devices (carts) list");
		return -1;
	}

	//TODO: Probably convert json to object instead of array
	getDeviceJsonByName (deviceId)
	{
		for (let i = 0; i < this.json.length; i++)
		{
			if (this.json[i].id === deviceId)
			{
				return this.json[i];
			}
		}
	}


	onTouchPrev (evt)
	{
		evt.stopPropagation();
		if (!this.active) return;
		if (!this.enabled) return;
		if (!this.prevActive) return;

		this.currentDevice--;
		if (this.currentDevice < 0) this.currentDevice = this.deviceCount - 1;

		//Get current device name
		this.currentDeviceId = this.devices[this.currentDevice].name;

		this.move(1);
		this.changeDeviceTitle();

		this.stateMngr.publishEvent("prevDevice", this.currentDeviceId);
		this.stateMngr.publishEvent("analytics", {"event": "product", "data": this.currentDeviceId});
	}

	onTouchNext (evt)
	{
		evt.stopPropagation();
		if (!this.active) return;
		if (!this.enabled) return;
		if (!this.nextActive) return;

		this.currentDevice++;
		if (this.currentDevice >= this.deviceCount) this.currentDevice = 0;

		//Get current device name
		this.currentDeviceId = this.devices[this.currentDevice].name;

		this.move(-1);
		this.changeDeviceTitle();

		this.stateMngr.publishEvent("nextDevice", this.currentDeviceId);
		this.stateMngr.publishEvent("analytics", {"event": "product", "data": this.currentDeviceId});
	}

	createHtmlElements ()
	{
		const root = document.querySelector("#bat_UI");

		this.title = document.createElement("div");
		this.title.classList.add("productTitle");
		this.title.style.visibility = "hidden";
		this.title.style.opacity = 0;
		root.appendChild(this.title);

		this.subtitle = document.createElement("div");
		this.subtitle.classList.add("productSubtitle");
		this.subtitle.style.visibility = "hidden";
		this.subtitle.style.opacity = 0;
		root.appendChild(this.subtitle);

		//Position text caption underneath main target box
		const top = (this.targets[1].height * 0.5) + this.targets[1].screenPos.y;
		this.title.style.top = top + "px";
		this.subtitle.style.top = (this.title.offsetHeight + top) + "px";

		this.btnPrev = document.createElement("div");
		this.btnPrev.classList.add("arrow");
		this.btnPrev.classList.add("arr_prev");
		this.btnPrev.style.visibility = "hidden";
		this.btnPrev.style.opacity = 0;
		root.appendChild(this.btnPrev);

		this.btnNext = document.createElement("div");
		this.btnNext.classList.add("arrow");
		this.btnNext.classList.add("arr_next");
		this.btnNext.style.visibility = "hidden";
		this.btnNext.style.opacity = 0;
		root.appendChild(this.btnNext);

		this.btnPrev.addEventListener("mousedown", evt => this.onTouchPrev(evt), false);
		this.btnNext.addEventListener("mousedown", evt => this.onTouchNext(evt), false);

		// this.createTargetDebugRects();
	}

	createTargets ()
	{
		this.targets = [];

		this.targets.push({width: 60, height: 80});
		this.targets.push({width: 320, height: 320});
		this.targets.push({width: 60, height: 80});
		this.targets.push({width: 60, height: 80});
		this.targets.push({width: 60, height: 80});

		this.root = document.querySelector("#bat_UI");
	}

	positionTargets ()
	{
		if (this.targets === undefined) this.createTargets();

		const sw = this.root.offsetWidth;
		const halfWide = this.root.offsetWidth * 0.5;
		const halfHigh = this.root.offsetHeight * 0.5;

		const t0 = this.targets[0];
		const t1 = this.targets[1];
		const t2 = this.targets[2];
		const t3 = this.targets[3];
		const t4 = this.targets[4];

		//Setup screen positions
		this.setScreenPos(t0, 42 + (t0.width * 0.5), halfHigh - 24);
		this.setScreenPos(t1, halfWide, halfHigh - 10);
		this.setScreenPos(t2, sw - 42 - (t2.width * 0.5), halfHigh - 24);
		this.setScreenPos(t3, sw + 42 + (t3.width * 0.5), halfHigh - 24);
		this.setScreenPos(t4, -42 - (t4.width * 0.5), halfHigh - 24);

		//Setup world positions
		this.setWorldPos(t0, -200);
		this.setWorldPos(t1, 500);
		this.setWorldPos(t2, -200);
		this.setWorldPos(t3, -300);
		this.setWorldPos(t4, -300);
	}

	setScreenPos (obj, x, y)
	{
		const w = obj.width * 0.5;
		const h = obj.height * 0.5;

		obj.screenPos = { x: x, y: y };
		obj.topLeft = { x: x - w, y: y + h };
		obj.btmRight = { x: x + w, y: y - h };
	}

	setWorldPos (obj, dist)
	{
		const world = {};
		const d = this.camera.position.z - dist;

		world.pos = this.camera.getWorldSpacePosition(obj.screenPos, d);
		world.topLeft = this.camera.getWorldSpacePosition(obj.topLeft, d);
		world.btmRight = this.camera.getWorldSpacePosition(obj.btmRight, d);

		world.width = world.btmRight.x - world.topLeft.x;
		world.height = world.btmRight.y - world.topLeft.y;

		//HACK: Fix the width maybe
		world.width = world.height * (obj.width / obj.height);

		obj.world = world;
	}

	createTargetDebugRects ()
	{
		//left
		this.root = document.querySelector("#bat_UI");
		this.debugRectL = document.createElement("div");
		this.debugRectL.style.position = "absolute";
		this.debugRectL.style.border = "1px solid cyan";
		this.debugRectL.style.width = this.targets[0].width + "px";
		this.debugRectL.style.height = this.targets[0].height + "px";
		this.debugRectL.style.left = this.targets[0].screenPos.x + "px";
		this.debugRectL.style.top = this.targets[0].screenPos.y + "px";
		this.debugRectL.style.marginLeft = -(this.targets[0].width * 0.5) + "px";
		this.debugRectL.style.marginTop = -(this.targets[0].height * 0.5) + "px";
		this.root.appendChild(this.debugRectL);

		//center
		this.debugRectC = document.createElement("div");
		this.debugRectC.style.position = "absolute";
		this.debugRectC.style.border = "1px solid cyan";
		this.debugRectC.style.width = this.targets[1].width + "px";
		this.debugRectC.style.height = this.targets[1].height + "px";
		this.debugRectC.style.left = this.targets[1].screenPos.x + "px";
		this.debugRectC.style.top = this.targets[1].screenPos.y + "px";
		this.debugRectC.style.marginLeft = -(this.targets[1].width * 0.5) + "px";
		this.debugRectC.style.marginTop = -(this.targets[1].height * 0.5) + "px";
		this.root.appendChild(this.debugRectC);

		//right
		this.debugRectR = document.createElement("div");
		this.debugRectR.style.position = "absolute";
		this.debugRectR.style.border = "1px solid cyan";
		this.debugRectR.style.width = this.targets[2].width + "px";
		this.debugRectR.style.height = this.targets[2].height + "px";
		this.debugRectR.style.left = this.targets[2].screenPos.x + "px";
		this.debugRectR.style.top = this.targets[2].screenPos.y + "px";
		this.debugRectR.style.marginLeft = -(this.targets[2].width * 0.5) + "px";
		this.debugRectR.style.marginTop = -(this.targets[2].height * 0.5) + "px";
		this.root.appendChild(this.debugRectR);
	}
}
