import * as THREE from "three";
import gsap from "gsap";
import Math2 from "../utils/math2"

export default class DiscTemplateVelo
{
	constructor () {}

	static initTemplate (manager, discObj, current, details)
	{
		this.manager = manager;
		this.discObj = discObj;
		this.current = current;
		this.T_SIZE = 1024;

		this.units = details.units;
		this.btnMore = details.btn_more_info;
		this.btnBack = details.btn_back;

		const closeImg = this.manager.loader.getAsset("btn_close");
		const circleImg = this.manager.loader.getAsset("circle");
		const btnImg = this.manager.loader.getAsset("btn");
		const dots_0 = this.manager.loader.getAsset("dots_0");
		const dots_1 = this.manager.loader.getAsset("dots_1");
		const dots_2 = this.manager.loader.getAsset("dots_2");
		const dots_3 = this.manager.loader.getAsset("dots_3");
		const dots_4 = this.manager.loader.getAsset("dots_4");

		this.closeImg = new Image();
		this.closeImg.src = closeImg;
		this.circleImg = new Image();
		this.circleImg.src = circleImg;
		this.btnImg = new Image();
		this.btnImg.src = btnImg;

		this.dots_0 = new Image();
		this.dots_1 = new Image();
		this.dots_2 = new Image();
		this.dots_3 = new Image();
		this.dots_4 = new Image();
		this.dots_0.src = dots_0;
		this.dots_1.src = dots_1;
		this.dots_2.src = dots_2;
		this.dots_3.src = dots_3;
		this.dots_4.src = dots_4;
	}

	static clear (tex)
	{
		const ctx = tex.image.getContext("2d");
		ctx.setTransform(1, 0, 0, 1, 0, 0);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);
	}

	static updateFrontTexture (tex, bg)
	{
		if (!this.discObj || !this.current) console.error("Disc template vars not implemented");
		if (!this.discObj || !this.current) return;

		let json = this.discObj.json;

		const ctx = tex.image.getContext("2d");
		document.body.appendChild(ctx.canvas);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);

		//Draw background image
		if (bg)
		{
			ctx.globalAlpha = json.alpha_inside || 1.0;
			ctx.drawImage(bg, 0, 0, this.T_SIZE, this.T_SIZE);
			ctx.globalAlpha = 1.0;
		}

		//Set up text format
		ctx.fillStyle = "#fff";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		let px, py;

		//Reset hitboxes
		let hitboxA = this.current.getObjectByName("MORE_A");
		let hitboxB = this.current.getObjectByName("MORE_B");
		hitboxA.position.set(0, 0, 0);
		hitboxB.position.set(0, 0, 0);
		hitboxA.scale.set(0, 0, 0);
		hitboxB.scale.set(0, 0, 0);

		//Close Button
		ctx.drawImage(this.closeImg, 512 - 35, 68, 76, 76);

		//Flavour category
		if (json.category)
		{
			ctx.font = "500 22px Velo";
			ctx.canvas.style.letterSpacing = "0px";
			let catTxt = json.category;
			let catMtx = ctx.measureText(catTxt);

			if (catMtx.width <= 700) ctx.fillText(catTxt, 512, 196);
			else this.drawTextInRect(ctx, catTxt, 512, 196, 700, 34);
		}

		//Name
		ctx.canvas.style.letterSpacing = "2px";
		ctx.font = "500 80px Velo";
		const txt = json.name.split("\n");
		for (let i = 0; i < txt.length; i++)
		{
			py = 275;
			py += (i * 80) - ((txt.length-1) * 40);
			ctx.fillText(txt[i], 512, py);
		}

		//Description
		ctx.canvas.style.letterSpacing = "0px";
		ctx.font = "400 38px Gotham";
		py = this.drawTextInRect(ctx, json.descriptor, 512, 380, 900, 50);

		//If description text is too long, offset height of remaining UI
		py -= 550;
		if (py < 0) py = 0;

		//COLUMNS:
		px = 0;
		const vars = json.variants.length;
		for (let i = 0; i < vars; i++)
		{
			ctx.canvas.style.letterSpacing = "1px";
			ctx.fillStyle = "#fff";

			px = (i * 400) - ((vars-1) * 200);
			px += 512;

			if (i === 0)
			{
				hitboxA.position.set((px - 512) / 1024 * 100, -25, 2);
				if (vars <= 1) hitboxA.position.set((px - 512) / 1024 * 100, -30, 2);
				hitboxA.scale.set(0.22, 0.32, 0.22);
			}
			else if (i > 0)
			{
				hitboxB.position.set((px - 512) / 1024 * 100, -25, 2);
				hitboxB.scale.set(0.22, 0.32, 0.22);

				ctx.fillRect(px - 201, 600 + py, 3, 200);
			}

			//20 pouch can
			ctx.font = "500 28px Velo";
			ctx.fillText(json.variants[i].title, px, 586 + py);

			//Price
			ctx.font = "700 84px Gotham";
			ctx.fillText(json.variants[i].price, px, 660 + py);

			//Measure price
			let price_metrics = ctx.measureText(json.variants[i].price);
			let sx;

			//plus tax
			if (json.variants[i].tax)
			{
				ctx.font = "600 17px Velo";
				sx = price_metrics.width * 0.5;
				sx += 42;
				const tax = json.variants[i].tax.split("\n");
				ctx.fillText(tax[0], px + sx, 634 + py);
				ctx.fillText(tax[1], px + sx - 5, 656 + py);
			}

			//Does the variant use DOTS for strength?
			let dots = json.variants[i].dots;
			if (dots === undefined)
			{
				//Use normal strength design
				let strengths = json.variants[i].nic_levels;
				ctx.font = "500 42px Velo";
				sx = "";
				for (let s = 0; s < strengths.length; s++)
				{
					if (s != 0) sx += " / ";
					sx += strengths[s];
				}
				ctx.fillText(sx, px, 764 + py);

				//Nicotine per
				ctx.canvas.style.letterSpacing = "0px";
				ctx.font = "500 26px Velo";
				ctx.fillStyle = "#fff";
				ctx.fillText(json.variants[i].per, px, 810 + py);
			}
			else
			{
				//Use the new DOTS design
				let strengths = json.variants[i].nic_levels;
				if (strengths.length !== dots.length)
					console.error("ERROR: JSON dots don't match strengths");

				ctx.canvas.style.letterSpacing = "0px";
				ctx.font = "500 26px Gotham";
				ctx.fillStyle = "#fff";

				for (let s = 0; s < dots.length; s++)
				{
					//MG
					let s_txt = strengths[s] + " MG";
					let s_pos = (px + (s * 174));
					ctx.fillText(s_txt, s_pos - ((dots.length-1)*87), 768 + py);

					let s_val = this.dots_0;
					if (dots[s] === 1) s_val = this.dots_1;
					if (dots[s] === 2) s_val = this.dots_2;
					if (dots[s] === 3) s_val = this.dots_3;
					if (dots[s] === 4) s_val = this.dots_4;

					//Dots
					ctx.drawImage(s_val, s_pos - 87 - ((dots.length-1)*87), 790 + py, 174, 25);

					//Draw a divider
					if (s != 0)
					{
						ctx.fillRect(s_pos - ((dots.length-1)*87) - 87, 740 + py, 2, 94);
					}
				}
			}


			//No 'more' button required if no features text
			if (json.variants[i].features === undefined || json.variants[i].features === "")
			{
				//disable hitbox
				hitboxA.position.set(0, 0, 0);
				hitboxB.position.set(0, 0, 0);
				hitboxA.scale.set(0, 0, 0);
				hitboxB.scale.set(0, 0, 0);

				continue;
			}


			//More Button
			ctx.canvas.style.letterSpacing = "3px";
			ctx.font = "700 24px Gotham";
			ctx.fillStyle = json.color_flavour; //"#000";
			ctx.drawImage(this.btnImg, px - 84, 870 + py, 166, 66);
			ctx.fillText(this.btnMore, px, 905 + py);
			// console.log(py) //0 :(
		}

		//TEMP
		// const img = new Image();
		// img.src = './textures/velo_overlay.png';
		// img.onload = function (e) {
		// 	ctx.drawImage(img, 0, 0, 1024, 1024);
		// 	tex.needsUpdate = true;
		// }

		document.body.removeChild(ctx.canvas);
		tex.needsUpdate = true;
	}

	static updateBackTexture (tex, v, bg)
	{
		if (!this.discObj || !this.current) console.error("Disc template vars not implemented");
		if (!this.discObj || !this.current) return;

		let json = this.discObj.json;

		const ctx = tex.image.getContext("2d");
		document.body.appendChild(ctx.canvas);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);

		//Draw background image
		if (bg)
		{
			ctx.globalAlpha = json.alpha_inside || 1.0;
			ctx.drawImage(bg, 0, 0, this.T_SIZE, this.T_SIZE);
			ctx.globalAlpha = 1.0;
		}

		//Set up text format
		ctx.fillStyle = "#fff";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		let py = 0;

		//Close Button
		ctx.drawImage(this.closeImg, 512 - 35, 68, 76, 76);

		//Flavour category
		if (json.category)
		{
			ctx.canvas.style.letterSpacing = "0px";
			ctx.font = "600 22px Velo";
			let catTxt = json.category;
			let catMtx = ctx.measureText(catTxt);

			if (catMtx.width <= 700) ctx.fillText(catTxt, 512, 196);
			else this.drawTextInRect(ctx, catTxt, 512, 196, 700, 34);
		}

		//Name
		ctx.canvas.style.letterSpacing = "2px";
		ctx.font = "600 80px Velo";
		const txt = json.name.split("\n");
		for (let i = 0; i < txt.length; i++)
		{
			py = 275;
			py += (i * 80) - ((txt.length-1) * 40);
			ctx.fillText(txt[i], 512, py);
		}

		py += 50;

		//Features (V)
		ctx.canvas.style.letterSpacing = "0px";
		ctx.font = "400 32px Velo";
		for (let i = 0; i < json.variants[v].features.length; i++)
		{
			py = this.drawTextInRect(ctx, json.variants[v].features[i], 512, py + 50, 860, 50);
		}
/*
		//vg
		ctx.font = "700 34px Gotham";
		let vgTxt = json.variants[v].vg;
		if (vgTxt !== undefined) ctx.fillText(vgTxt, 512, 375);

		//Push text down if needed
		if (py < 646) py = 646;

		//Advice
		ctx.canvas.style.letterSpacing = "2px";
		ctx.font = "500 26px Gotham";
		py = this.drawTextInRect(ctx, json.advice, 512, py, 720, 48);
		console.log(py);
*/

		//Push button down if needed
		if (py < 870) py = 870;

		//Back button
		ctx.canvas.style.letterSpacing = "3px";
		ctx.font = "700 24px Gotham";
		ctx.fillStyle = json.color_flavour; //"#000";
		ctx.drawImage(this.btnImg, 512 - 83, py, 166, 66);
		ctx.fillText(this.btnBack, 512, py + 35);


		document.body.removeChild(ctx.canvas);
		tex.needsUpdate = true;
	}

	static drawTextInRect (context, text, x, y, maxWidth, lineHeight)
	{
		if (text === undefined) return;

		const words = text.split("\n");

		for (let i = 0; i < words.length; i++)
		{
			y = this.drawParagraphInRect(context, words[i], x, y, maxWidth, lineHeight);
			y += lineHeight;
		}

		return y;
	}

	static drawParagraphInRect (context, text, x, y, maxWidth, lineHeight)
	{
		const words = text.split(" ");
		let testLine, metrics;
		let line = "";

		for (let n = 0; n < words.length; n++)
		{
			testLine = line + words[n] + " ";
			metrics = context.measureText(testLine);

			if (metrics.width > maxWidth && n > 0)
			{
				context.fillText(line, x, y);
				line = words[n] + " ";
				y += lineHeight;
			}
			else
			{
				line = testLine;
			}
		}

		context.fillText(line, x, y);

		return y;
	}

	static measureParagraphHeight (context, text, maxWidth, lineHeight)
	{
		if (text === undefined) return -1;

		let words, metrics, testLine;
		let y = 0, line = "";
		let lines = text.split("\n");

		for (let i = 0; i < lines.length; i++)
		{
			words = lines[i].split(" ");

			for (let n = 0; n < words.length; n++)
			{
				testLine = line + words[n] + " ";
				metrics = context.measureText(testLine);

				if (metrics.width > maxWidth && n > 0 && n < words.length - 1)
				{
					line = words[n] + " ";
					y += lineHeight;
				}
				else
				{
					line = testLine;
				}
			}

			y += lineHeight;
			line = "";
		}
		return y;
	}
}
