/*
 *  Disc View
 *  Use this as an object to manage main disc positions & movement
 *  This applies disc templates based on deviceId
 */

import * as THREE from "three";
import gsap from "gsap";
import Math2 from "./utils/math2"
import DiscDefault from "./discs/disc_default"
import DiscTemplateVelo from "./discs/disc_velo"
import DiscTemplateVuseUS from "./discs/disc_vuse_us"
import DiscTemplateVeloUS from "./discs/disc_velo_us"
import DiscTemplateZA from "./discs/disc_za"

export default class DiscView
{
	constructor (manager)
	{
		this.manager = manager;
		this.camera = manager.camera;
		this.raycaster = new THREE.Raycaster();
		this.intersect = [];
		this.T_SIZE = 1024;

		this.pool = [];
		this.pool.push(this.createView("one"));
		this.pool.push(this.createView("two"));

		this.current = 0;
		this.bufferID = 0;
		this.bg = undefined;
		this.json = undefined;
		this.isOpen = false;
	}

	init (details)
	{
		this.details = details;

		DiscDefault.preload(this.manager);
		DiscTemplateZA.preload(this.manager);
		DiscTemplateVuseUS.preload(this.manager);

		this.manager.addToUpdate(() => this.update(), "disc_view");
	}

	//HACK: Constantly update the disc texture!!
	update ()
	{
		if (this.isOpen)
		{
			this.pool[this.current].children[0].material.map.needsUpdate = true;
		}
	}

	reset ()
	{
		for (let i = 0; i < this.pool.length; i++)
		{
			const obj = this.pool[i];
			const front = obj.children[0].material;
			const back = obj.children[1].material;

			gsap.killTweensOf(front);
			gsap.killTweensOf(back);
			gsap.killTweensOf(obj.rotation);

			front.opacity = 0;
			obj.rotation.y = 0;
		}

		this.isOpen = false;
	}

	getDiscTemplate (deviceId)
	{
		if (this.manager.country === "ZA")
		{
			switch (deviceId) {
				case "velo_loz":
				case "velo_pouch":
				case "velo_pouch_can":
					return DiscTemplateVelo;
					break;
				default:
					return DiscTemplateZA;
					break;
			}
		}

		if (this.manager.country === "US")
		{
			switch (deviceId) {
				case "velo_loz":
				case "velo_pouch":
				case "velo_pouch_can":
				case "velo_pouch_mini":
					return DiscTemplateVeloUS;
					break;
				case "alto":
				case "vibe":
				case "solo":
				case "ciro":
					return DiscTemplateVuseUS;
					break;
			}
		}

		switch (deviceId) {
			case "velo_loz":
			case "velo_pouch":
			case "velo_pouch_can":
			case "velo_pouch_mini":
				return DiscTemplateVelo;
				break;
			default:
				return DiscDefault;
		}
	}

	open (discObj, bg)
	{
		//Select next view object in pool
		this.current++;
		if (this.current >= this.pool.length) this.current = 0;
		const obj = this.pool[this.current];
		const front = obj.children[0].material; //gets material from child 0 of 'view' Group

		this.bg = bg;

		//Update front texture
		this.discTemplate = this.getDiscTemplate(discObj.deviceId);
		this.discTemplate.initTemplate(this.manager, discObj, this.pool[this.current], this.details);
		this.discTemplate.updateFrontTexture(front.map, bg);


		//Position in front of disc
		obj.scale.copy(discObj.scale);
		obj.position.copy(discObj.targetPos);
		front.opacity = 0;

		//Fade in
		gsap.killTweensOf(front);
		gsap.to(front, {
			duration: 1,
			opacity: 1,
			delay: 0.5
		});

		this.isOpen = true;
	}

	openA ()
	{
		const obj = this.pool[this.current];
		const back = obj.children[1].material;

		//Update back texture
		this.discTemplate.updateBackTexture(back.map, 0, this.bg);

		this.flip();

		this.isOpen = true;
	}

	openB ()
	{
		const obj = this.pool[this.current];
		const back = obj.children[1].material;

		//Update back texture
		this.discTemplate.updateBackTexture(back.map, 1, this.bg);

		this.flip();

		this.isOpen = true;
	}

	flip ()
	{
		const obj = this.pool[this.current];

		gsap.killTweensOf(obj.rotation);
		gsap.to(obj.rotation, {
			duration: 1,
			y: -Math.PI
		});

		this.isOpen = true;
	}

	unflip ()
	{
		const obj = this.pool[this.current];

		gsap.killTweensOf(obj.rotation);
		gsap.to(obj.rotation, {
			duration: 1,
			y: 0
		});

		this.isOpen = true;
	}

	close (discObj)
	{
		const obj = this.pool[this.current];
		const front = obj.children[0].material;
		const back = obj.children[1].material;

		gsap.killTweensOf(front);
		gsap.to(front, {
			duration: 0.4,
			opacity: 0,
			onComplte: () =>
			{
				this.discTemplate.clear(front.map);
				front.needsUpdate = true;
			}
		});

		gsap.killTweensOf(obj.rotation);
		gsap.to(obj.rotation, {
			duration: 1,
			y: 0
		});

		this.isOpen = false;
		this.bg = undefined;

		// obj.position.copy(new THREE.Vector3(10000, 0, 0));
	}

	onTouch (discId, mouse)
	{
		const obj = this.pool[this.current];
		this.intersect.length = 0;
		this.raycaster.setFromCamera(mouse, this.camera);
		this.raycaster.intersectObject(obj, true, this.intersect);
		if (this.intersect.length === 0) return;

		for (let i = 0; i < this.intersect.length; i++)
		{
			switch (this.intersect[i].object.name)
			{
				case "CLOSE":
					this.manager.state.publishEvent("closeDisc", discId);
					break;

				case "BACK":
					this.unflip();
					this.manager.state.publishEvent("unflipDisc", discId);
					break;

				case "MORE_A":
					this.openA();
					this.manager.state.publishEvent("flipDisc", discId);
					break;

				case "MORE_B":
					this.openB();
					this.manager.state.publishEvent("flipDisc", discId);
					break;
			}
		}
	}

	createView (id)
	{
		const c1 = document.createElement("canvas");
		c1.style.backgroundImage = "none";
		c1.id = "front_" + id;
		c1.width = this.T_SIZE;
		c1.height = this.T_SIZE;

		const c2 = document.createElement("canvas");
		c2.style.backgroundImage = "none";
		c2.id = "back_" + id;
		c2.width = this.T_SIZE;
		c2.height = this.T_SIZE;

		const matA = new THREE.MeshBasicMaterial();
		matA.name = "matA_" + id;
		matA.map = new THREE.CanvasTexture(c1);
		matA.transparent = true;
		matA.depthTest = false;

		const matB = new THREE.MeshBasicMaterial();
		matB.name = "matB_" + id;
		matB.map = new THREE.CanvasTexture(c2);
		matB.transparent = true;
		matB.depthTest = false;

		const matC = new THREE.MeshBasicMaterial();

		const geom = new THREE.PlaneBufferGeometry(100, 100);
		const front = new THREE.Mesh(geom, matA);
		const rear = new THREE.Mesh(geom, matB);
		rear.rotation.y += Math.PI;
		rear.position.z -= 1;
		front.position.z += 1;

		//Close buttons
		const closeA = new THREE.Mesh(geom, matC);
		closeA.name = "CLOSE";
		closeA.scale.set(0.2, 0.2, 0.2);
		closeA.position.set(0, 40, 2);
		closeA.visible = false;

		const closeB = new THREE.Mesh(geom, matC);
		closeB.name = "CLOSE";
		closeB.scale.set(0.2, 0.2, 0.2);
		closeB.position.set(0, 40, -2);
		closeB.rotation.set(0, Math.PI, 0);
		closeB.visible = false;

		//More buttons
		const moreA = new THREE.Mesh(geom, matC);
		moreA.name = "MORE_A";
		moreA.position.set(0, 0, 0);
		moreA.scale.set(0, 0, 0);
		moreA.visible = false;

		const moreB = new THREE.Mesh(geom, matC);
		moreB.name = "MORE_B";
		moreB.position.set(0, 0, 0);
		moreB.scale.set(0, 0, 0);
		moreB.visible = false;

		//Back button
		const back = new THREE.Mesh(geom, matC);
		back.name = "BACK";
		back.scale.set(0.2, 0.2, 0.2);
		back.position.set(0, -37, -2);
		back.rotation.set(0, Math.PI, 0);
		back.visible = false;

		const view = new THREE.Group();
		view.name = id;
		view.add(front);
		view.add(rear);
		view.add(closeA);
		view.add(closeB);
		view.add(moreA);
		view.add(moreB);
		view.add(back);
		this.manager.add(view);


		// //TEMP: Append view to screen
		// document.body.appendChild(c1);
		// c1.style.position = "absolute";
		// c1.style.top = "0px";
		// c1.style.left = "0px";
		// c1.style.width = "200px";
		// c1.style.height = "200px";
		//
		// if (id === "two")
		// {
		// 	c1.style.left = "200px";
		// 	c1.style.background = "#f90";
		// }

		return view;
	}

}
