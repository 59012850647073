import * as THREE from "three";
import gsap from "gsap";
import Math2 from "../utils/math2"

export default class DiscTemplateVuseUS
{
	constructor () {}

	static preload (manager)
	{
		this.manager = manager;

		this.closeImg = new Image();
		this.btnImg = new Image();
		this.closeImg.src = this.manager.loader.getAsset("btn_close");
		this.btnImg.src = this.manager.loader.getAsset("btn");

		this.bars = {};
		this.bars.bars3_0 = new Image();
		this.bars.bars3_1 = new Image();
		this.bars.bars3_2 = new Image();
		this.bars.bars3_3 = new Image();
		this.bars.bars3_0.src = this.manager.loader.getAsset("bars3_0");
		this.bars.bars3_1.src = this.manager.loader.getAsset("bars3_1");
		this.bars.bars3_2.src = this.manager.loader.getAsset("bars3_2");
		this.bars.bars3_3.src = this.manager.loader.getAsset("bars3_3");
	}

	static initTemplate (manager, discObj, current, details)
	{
		this.manager = manager;
		this.discObj = discObj;
		this.current = current;
		this.T_SIZE = 1024;

		this.units = details.units;
		this.btnMore = details.btn_more_info;
		this.btnBack = details.btn_back;
	}

	static clear (tex)
	{
		const ctx = tex.image.getContext("2d");
		ctx.setTransform(1, 0, 0, 1, 0, 0);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);
	}

	static updateFrontTexture (tex, bg)
	{
		if (!this.discObj || !this.current) console.error("Disc template vars not implemented");
		if (!this.discObj || !this.current) return;

		let json = this.discObj.json;

		const ctx = tex.image.getContext("2d");
		document.body.appendChild(ctx.canvas);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);

		//Set up text format
		ctx.fillStyle = "#fff";
		ctx.textAlign = "center";
		ctx.textBaseline = "top";
		let px = 0, py = 0;

		//Reset hitboxes
		let hitboxA = this.current.getObjectByName("MORE_A");
		let hitboxB = this.current.getObjectByName("MORE_B");
		hitboxA.position.set(0, 0, 0);
		hitboxB.position.set(0, 0, 0);
		hitboxA.scale.set(0, 0, 0);
		hitboxB.scale.set(0, 0, 0);

		//Draw white background
		ctx.beginPath();
		ctx.arc(512, 512, 511, 0, Math.PI * 2, true);
		ctx.fill();

		//Draw background image
		if (bg)
		{
			ctx.globalAlpha = json.alpha_inside || 1.0;
			ctx.drawImage(bg, 0, 0, this.T_SIZE, this.T_SIZE);
			ctx.globalAlpha = 1.0;
		}

		//Close Button
		ctx.drawImage(this.closeImg, 512 - 35, 68, 76, 76); //y = 68

		//Count lines in title text
		const txt = json.name.split("\n");

		//Measure text
		const btnBtm = 68 + 76 + 10;
		ctx.font = "600 76px Gotham";
		const nameHeight = this.measureParagraphHeight(ctx, json.name, 900, 80);
		ctx.font = "500 42px Gotham";
		const descHeight = this.measureParagraphHeight(ctx, json.descriptor, 900, 50);

		//Fit text
		let spacing = ((600 - btnBtm) - nameHeight - descHeight) / 3;
		if (spacing < 10) spacing = 10;

		//Name
		ctx.fillStyle = json.color_flavour;
		ctx.canvas.style.letterSpacing = "1px";
		ctx.font = "600 76px Gotham";
		for (let i = 0; i < txt.length; i++)
		{
			py = btnBtm + spacing;
			py += i * 80;
			ctx.fillText(txt[i], 512, py);
		}

		let descPos = py + 80 + spacing;

		//Description
		ctx.fillStyle = "#000";
		ctx.canvas.style.letterSpacing = "-0.5px";
		ctx.font = "500 42px Gotham";
		py = this.drawTextInRect(ctx, json.descriptor, 512, descPos, 900, 50); //old y = 416

		//If description text is too long, offset height of remaining UI
		py -= 600;
		if (py < 0) py = 0;

		//COLUMNS:
		px = 0;
		const vars = json.variants.length;
		for (let i = 0; i < vars; i++)
		{
			ctx.canvas.style.letterSpacing = "1px";
			ctx.fillStyle = "#000";

			px = (i * 400) - ((vars-1) * 200);
			px += 512;

			if (i === 0)
			{
				hitboxA.position.set((px - 512) / 1024 * 100, -25, 2);
				if (vars <= 1) hitboxA.position.set((px - 512) / 1024 * 100, -30, 2);
				hitboxA.scale.set(0.22, 0.32, 0.22);
			}
			else if (i > 0)
			{
				hitboxB.position.set((px - 512) / 1024 * 100, -25, 2);
				hitboxB.scale.set(0.22, 0.32, 0.22);

				ctx.fillRect(px - 201, 600 + py, 3, 200);
			}

			ctx.font = "500 24px Gotham";
			ctx.fillText(json.variants[i].title, px, 612 + py);
			ctx.font = "600 70px Gotham";
			ctx.fillText(json.variants[i].price, px - 12, 660 + py);

			//Measure price
			let price_metrics = ctx.measureText(json.variants[i].price);

			//plus tax
			ctx.font = "500 17px Gotham";
			ctx.textAlign = "left";
			let sx = price_metrics.width * 0.5;
			this.drawTextInRect(ctx, json.variants[i].tax, px + sx, 660 + py, 100, 22);

			//Strengths
			const bars = json.variants[i].bars;
			const units = json.variants[i].flavour_units;
			const strengths = json.variants[i].nic_levels;
			if (strengths.length > 0 && bars === undefined) this.drawNIC_percent(strengths, units, ctx, json.color_flavour, px, py);
			if (strengths.length > 0 && bars != undefined && bars.length > 0) this.drawNIC_bars(bars, strengths, units, ctx, px, py + 40);
			if (strengths.length > 0 && bars != undefined && bars.length === 0) this.drawNIC_percent(strengths, units, ctx, json.color_flavour, px, py);
			/*
			ctx.font = "500 32px Gotham";
			ctx.textAlign = "center";
			sx = 0;
			for (let s = 0; s < strengths.length; s++)
			{
				sx = (s * 120) - ((strengths.length-1) * 60);
				ctx.fillText(strengths[s], px + sx, 764 + py);
			}

			ctx.canvas.style.letterSpacing = "0px";
			ctx.font = "500 28px Gotham";
			ctx.fillStyle = "#000";
			ctx.fillText(json.variants[i].per, px, 806 + py);
			*/

			//No 'more' button required if no features text
			if (json.variants[i].features === undefined)
			{
				//disable hitbox
				hitboxA.position.set(0, 0, 0);
				hitboxB.position.set(0, 0, 0);
				hitboxA.scale.set(0, 0, 0);
				hitboxB.scale.set(0, 0, 0);

				continue;
			}

			//More Button
			ctx.canvas.style.letterSpacing = "1px";
			ctx.font = "500 22px Gotham";
			ctx.fillStyle = "#fff";
			ctx.textBaseline = "top";
			ctx.drawImage(this.btnImg, px - 84, 870 + py, 166, 66);
			ctx.fillText(this.btnMore, px, 892 + py);
		}

		document.body.removeChild(ctx.canvas);
		tex.needsUpdate = true;
	}

	static updateBackTexture (tex, v, bg)
	{
		if (!this.discObj || !this.current) console.error("Disc template vars not implemented");
		if (!this.discObj || !this.current) return;

		let json = this.discObj.json;

		const ctx = tex.image.getContext("2d");
		document.body.appendChild(ctx.canvas);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);

		//Set up text format
		ctx.fillStyle = "#fff";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		let py = 0;

		//Draw white background
		ctx.beginPath();
		ctx.arc(512, 512, 511, 0, Math.PI * 2, true);
		ctx.fill();

		//Draw background image
		if (bg)
		{
			ctx.globalAlpha = json.alpha_inside || 1.0;
			ctx.drawImage(bg, 0, 0, this.T_SIZE, this.T_SIZE);
			ctx.globalAlpha = 1.0;
		}

		//Close Button
		ctx.drawImage(this.closeImg, 512 - 35, 68, 76, 76);

		//Name
		ctx.fillStyle = json.color_flavour;
		ctx.canvas.style.letterSpacing = "1px";
		ctx.font = "600 70px Gotham";
		const txt = json.name.split("\n");
		for (let i = 0; i < txt.length; i++)
		{
			py = 350;
			py += (i * 80) - ((txt.length-1) * 40);
			ctx.fillText(txt[i], 512, py);
		}

		//Push text down if needed
		if (py < 460) py = 460;

		//Features (V)
		ctx.canvas.style.letterSpacing = "1px";
		ctx.font = "500 40px Gotham";
		ctx.fillStyle = "#000";
		for (let i = 0; i < json.variants[v].features.length; i++)
		{
			py = this.drawTextInRect(ctx, json.variants[v].features[i], 512, py + 50, 910, 50);
		}

		//Push button down if needed
		if (py < 870) py = 870;

		//Back button
		ctx.canvas.style.letterSpacing = "1px";
		ctx.font = "500 22px Gotham";
		ctx.fillStyle = "#fff";
		ctx.drawImage(this.btnImg, 512 - 83, py, 166, 66);
		ctx.fillText(this.btnBack, 512, py + 35);

		//TEMP
		// const img = new Image();
		// img.src = './textures/velo_overlay.png';
		// img.onload = function (e) {
		// 	ctx.drawImage(img, 0, 0, 1024, 1024);
		// 	tex.needsUpdate = true;
		// }


		document.body.removeChild(ctx.canvas);
		tex.needsUpdate = true;
	}

	static drawTextInRect (context, text, x, y, maxWidth, lineHeight)
	{
		if (text === undefined) return;

		let temp = y;

		const words = text.split("\n");

		for (let i = 0; i < words.length; i++)
		{
			y = this.drawParagraphInRect(context, words[i], x, y, maxWidth, lineHeight);
			y += lineHeight;
		}

		return y;
	}

	static drawParagraphInRect (context, text, x, y, maxWidth, lineHeight)
	{
		const words = text.split(" ");
		let testLine, metrics;
		let line = "";

		for (let n = 0; n < words.length; n++)
		{
			testLine = line + words[n] + " ";
			metrics = context.measureText(testLine);

			if (metrics.width > maxWidth && n > 0)
			{
				context.fillText(line, x, y);
				line = words[n] + " ";
				y += lineHeight;
			}
			else
			{
				line = testLine;
			}
		}

		context.fillText(line, x, y);

		return y;
	}

	static measureParagraphHeight (context, text, maxWidth, lineHeight)
	{
		if (text === undefined) return -1;

		let words, metrics, testLine;
		let y = 0, line = "";
		let lines = text.split("\n");

		for (let i = 0; i < lines.length; i++)
		{
			words = lines[i].split(" ");

			for (let n = 0; n < words.length; n++)
			{
				testLine = line + words[n] + " ";
				metrics = context.measureText(testLine);

				if (metrics.width > maxWidth && n > 0 && n < words.length - 1)
				{
					line = words[n] + " ";
					y += lineHeight;
				}
				else
				{
					line = testLine;
				}
			}

			y += lineHeight;
			line = "";
		}
		return y;
	}


	//Draw NIC strengths
	static drawNIC_bars(bars, strengths, units, ctx, px, py)
	{
		ctx.font = "700 28px Gotham";
		ctx.fillStyle = "#000";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";

		let sx = 0, bar = "";
		for (let s = 0; s < strengths.length; s++)
		{
			if (strengths[s] === 0) ctx.font = "700 24px Gotham";
			else ctx.font = "700 28px Gotham";

			if (bars.indexOf(strengths[s]) === -1) console.error("ERROR: Bars array must contain values used in nic_levels array");

			bar = "bars" + Math.max(bars.length - 1, 3) + "_" + bars.indexOf(strengths[s]);

			sx = (s * 80) - ((strengths.length-1) * 40);
			ctx.drawImage(this.bars[bar], px + sx - 25, 710 + py, 50, 50);
			ctx.fillText(this.parseNIC(strengths[s]), px + sx, 732 + py + 54);
		}

		//Units
		if (units !== undefined)
		{
			ctx.canvas.style.letterSpacing = "-1px";
			ctx.font = "600 18px Gotham";
			ctx.fillStyle = "#000";
			ctx.fillText(units, px, 780 + py + 44);
		}
	}

	static drawNIC_percent(strengths, units, ctx, col, px, py)
	{
		ctx.font = "500 32px Gotham";
		ctx.fillStyle = col;
		ctx.textAlign = "center";

		let sx = 0;
		for (let s = 0; s < strengths.length; s++)
		{
			sx = (s * 120) - ((strengths.length-1) * 60);
			ctx.fillText(strengths[s], px + sx, 764 + py);
		}

		//Units
		if (units !== undefined)
		{
			ctx.canvas.style.letterSpacing = "0px";
			ctx.font = "500 28px Gotham";
			ctx.fillStyle = "#000";
			ctx.fillText(units, px, 806 + py);
		}

		/*
		ctx.font = "500 32px Gotham";
		ctx.textAlign = "center";
		sx = 0;
		for (let s = 0; s < strengths.length; s++)
		{
			sx = (s * 120) - ((strengths.length-1) * 60);
			ctx.fillText(strengths[s], px + sx, 764 + py);
		}

		ctx.canvas.style.letterSpacing = "0px";
		ctx.font = "500 28px Gotham";
		ctx.fillStyle = "#000";
		ctx.fillText(json.variants[i].per, px, 806 + py);
		*/
	}

	static parseNIC (nic)
	{
		if (nic === 0) return "zero";
		if (nic === "0") return "0";
		if (nic.toString().length === 1) return "0" + nic;

		return nic;
	}
}
