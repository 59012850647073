export default class JsonLoader {
  constructor() {}

  static load(url, onLoad, onProgress, onError) {
    fetch(url).then((response) => {
      if (response.status !== 200) {
        console.warn("Problem loading local content.json");
        console.error(response.status);
        return;
      }

      response.json().then((json) => onLoad(json));
    });
  }
}
