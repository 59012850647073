/*
 *  Disc Pool
 *  Manage the flavour discs for each brand/device
 */

import * as THREE from "three";
import Disc from "./disc";

export default class DiscPoolBuilder
{
	constructor (manager)
	{
		this.MAX_DISCS = 100;
		this.FONT_SIZE = 38;

		//Disc material
		this.discMat = new THREE.MeshLambertMaterial();
		this.discMat.color = new THREE.Color(0xffffff);
		this.discMat.envMap = manager.scene.environment;
		this.discMat.reflectivity = 0.4;

		this.manager = manager;
		this.discPool = {};
		this.allDiscs = [];

		this.currentDevice = "";
		this.lastDevice = "";
	}

	init (json, positions, mesh)
	{
		let pos = positions.landscape;
		if (!this.manager.landscape) pos = positions.portrait;

		//Create a disc for each device in the current brand
		this.createBrandMesh(json, mesh);
		this.setTargetPositions(pos);
	}

	changeDevice (deviceId)
	{
		this.setDevice(deviceId);

		for (let i = 0; i < this.discCount; i++)
		{
			this.currentPool[i].activate(this.currentDevice);
		}
	}

	showNextDevice (deviceId)
	{
		this.setDevice(deviceId);

		this.hideDiscs(this.discPool[this.lastDevice], -1);
		this.showDiscs(this.discPool[this.currentDevice], 1, 0.02);
	}

	showPrevDevice (deviceId)
	{
		this.setDevice(deviceId);

		this.hideDiscs(this.discPool[this.lastDevice], 1);
		this.showDiscs(this.discPool[this.currentDevice], -1, 0.02);
	}

	setDevice (deviceId)
	{
		this.currentPool = this.discPool[deviceId];
		this.discCount = this.currentPool.length;

		this.lastDevice = this.currentDevice;
		this.currentDevice = deviceId;
	}

	hideDiscs (discs, direction)
	{
		for (let i = 0; i < discs.length; i++)
		{
			discs[i].hide(direction);
		}
	}

	showDiscs (discs, direction, intv = 0.05)
	{
		for (let i = 0; i < discs.length; i++)
		{
			this.currentPool[i].activate(this.currentDevice);
			discs[i].show(i * intv, direction);
		}
	}

	getDiscById (discId)
	{
		return this.allDiscs[discId];
	}

	createBrandMesh (json, mesh)
	{
		this.geom = mesh.geometry.clone();

		this.instanceColors = [];
		this.instanceOffset = [];
		this.instanceOpacity = [];
		this.instanceMix = [];
		const flavour_names = [];
		let x, y, disc, i = 0;

		for (let d = 0; d < json.length; d++)
		{
			this.discPool[json[d].id] = [];

			for (let f = 0; f < json[d].flavours.length; f++)
			{
				//Create disc object
				disc = new Disc(i, json[d].flavours[f]);

				//Get name and font for disc label
				let obj = {};
				obj.name = disc.name;
				obj.font = this.getDiscFont(json[d].id);
				obj.col = disc.bgCol;
				obj.bg = disc.bgImg;
				obj.bgAlpha = disc.bgAlpha;
				obj.txtAlpha = disc.txtAlpha;
				flavour_names.push(obj);

				//Get colour
				this.instanceColors.push(disc.getRed());
				this.instanceColors.push(disc.getGreen());
				this.instanceColors.push(disc.getBlue());

				x = Math.floor(i / 10);
				y = i % 10;
				this.instanceOffset.push(x);
				this.instanceOffset.push(y);

				this.instanceOpacity.push(1.0);

				this.instanceMix.push(0.0);

				//Add to pools
				this.discPool[json[d].id].push(disc);
				this.allDiscs.push(disc);

				i++;
			}
		}

		this.geom.setAttribute("instanceColor", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceColors), 3));
		this.geom.setAttribute("instanceOffset", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceOffset), 2));
		this.geom.setAttribute("instanceOpacity", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceOpacity), 1));
		this.geom.setAttribute("instanceMix", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceMix), 1));

		//Create flavour names texture
		this.discMat.map = this.createTextCanvas(flavour_names);
		this.discMat.map.needsUpdate = true;

		//Modify disc material to add instance properties
		this.discMat.onBeforeCompile = shader => this.modifyShader(shader);

		//Create mesh
		this.mesh = new THREE.InstancedMesh(this.geom, this.discMat, flavour_names.length);

		if (flavour_names.length > this.MAX_DISCS) console.error("ERROR: Too many flavours");
	}

	setTargetPositions (positions)
	{
		//Loop through devices
		let flavs, cnt, pos;
		for (let [key, value] of Object.entries(this.discPool))
		{
			flavs = this.discPool[key];
			cnt = flavs.length;
			pos = positions[cnt - 1];

			for (let i = 0; i < cnt; i++)
			{
				//Hack to space out discs
				//if (this.manager.landscape) pos[i].pos[0] = pos[i].pos[0] * 1.25;

				flavs[i].setTarget(pos[i]);
			}
		}
	}

	update ()
	{
		let flagOpacityChange = false;
		let flagColorChange = false;
		let flagMixChange = false;
		let flagUvChange = false;
		let disc;

		// for (let i = 0; i < this.MAX_DISCS; i++)
		for (let i = 0; i < this.allDiscs.length; i++)
		{
			disc = this.allDiscs[i];
			disc.animate();

			if (disc.opacityFlag)
			{
				this.instanceOpacity[i] = disc.getTextOpacity();
				flagOpacityChange = true;
			}

			if (disc.colorFlag)
			{
				this.instanceColors[(i * 3) + 0] = disc.getRed();
				this.instanceColors[(i * 3) + 1] = disc.getGreen();
				this.instanceColors[(i * 3) + 2] = disc.getBlue();
				flagColorChange = true;
			}

			if (disc.mixFlag)
			{
				this.instanceMix[i] = disc.grayMix;
				flagMixChange = true;
			}

			this.mesh.setMatrixAt(i, disc.matrix);
		}

		//Update text opacities
		if (flagOpacityChange)
		{
			this.geom.setAttribute("instanceOpacity", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceOpacity), 1));
		}

		//Update colors
		if (flagColorChange)
		{
			this.geom.setAttribute("instanceColor", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceColors), 3));
		}

		//Update mix
		if (flagMixChange)
		{
			this.geom.setAttribute("instanceMix", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceMix), 1));
		}

		this.mesh.instanceMatrix.needsUpdate = true;
	}



	createTextCanvas (flavours)
	{
		const c = document.createElement("canvas");
		c.id = "discText";
		c.width = 4096;
		c.height = 4096;

		//letter spacing hack
		// c.style.letterSpacing = "2px";
		document.body.appendChild(c);

		const ctx = c.getContext("2d");

		//Fill background
		ctx.beginPath();
		ctx.fillStyle = "0";
		ctx.fillRect(0, 0, 4096, 4096);

		//Set up text format
		ctx.fillStyle = "#fff";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.font = "700 " + this.FONT_SIZE + "px Gotham";
		// ctx.font = "700 " + this.FONT_SIZE + "px Comic Sans MS";
		// ctx.font = "800 " + this.FONT_SIZE + "px Din";

		let num = 0;
		let txt = "";
		let txtAlpha = 1;

		for (let x = 0; x < 10; x++)
		{
			for (let y = 0; y < 10; y++)
			{
				num = (x * 10) + y;
				if (num >= flavours.length) break;

				ctx.font = flavours[num].font;
				txt = flavours[num].name.split("\n");
				txtAlpha = flavours[num].txtAlpha;

				//Draw block colour
				ctx.fillStyle = flavours[num].col;
				ctx.fillRect(x * 400, 3696 - (y * 400), 400, 400);
				ctx.fillStyle = "#ffffff";

				if (flavours[num].bg !== undefined)
				{
					const img = new Image();
					img.crossOrigin = "";
					const alph = flavours[num].bgAlpha;
					const t = txt;
					const ta = txtAlpha;
					const xx = x;
					const yy = y;
					img.onload = (e) => this.loadImageAndText(e, ctx, t, ta, alph, xx, yy);

					//Replace this with fetch
					// img.src = flavours[num].bg;
					// flavours[num].bg = "https://uat-assets-bat.s3-eu-west-1.amazonaws.com/education/origami/eliquid_blended_tobacco.png";

					fetch(flavours[num].bg + "?cacheblock=true", {
						method: "GET",
						mode: "cors",
						cache: "no-cache",
						headers: {
							Origin: window.location.origin
						}
					})
					.then(response => {
						if (!response.ok) {
							throw new Error("NETWORK ERROR: Fetch failed to load disc background");
						}
						return response.blob();
					})
					.then(blob => {
						img.src = URL.createObjectURL(blob);
					})
					.catch(error => {
						console.error("ERROR: Fetch request failed", error);
					});
				}
				else
				{
					this.loadText(ctx, txt, txtAlpha, x, y);
				}
			}

			if (num >= flavours.length) break;
		}

		//letter spacing hack
		document.body.removeChild(c);

		return new THREE.Texture(c);
	}

	loadImageAndText (e, ctx, txt, txtAlph, alph, x, y)
	{
		ctx.globalAlpha = alph || 1.0;
		ctx.drawImage(e.currentTarget, x * 400, 3696 - (y * 400), 400, 400);
		ctx.globalAlpha = 1.0;
		this.discMat.map.needsUpdate = true;

		this.loadText(ctx, txt, txtAlph, x, y);
	}

	loadText (ctx, txt, txtAlph, x, y)
	{
		if (txtAlph === 0) return;

		let px, py;
		const lines = txt.length;
		const tH = Math.round(this.FONT_SIZE * 1.1);

		for (let i = 0; i < lines; i++)
		{
			px = 200 + (x * 400);
			py = 3896 - (y * 400);
			py += (i * tH) - ((lines-1) * tH * 0.5);

			ctx.globalAlpha = txtAlph || 1.0;
			ctx.fillText(txt[i], px, py);
			ctx.globalAlpha = 1.0;
		}
	}

	//Get correct font for Disc
	//I'd prefer to get this from json or stylesheet, but reasons
	getDiscFont (deviceId)
	{
		switch (deviceId) {
			case "alto":
			case "vibe":
			case "solo":
			case "ciro":
				return "700 " + this.FONT_SIZE + "px Gotham";
				break;
			case "velo_loz":
			case "velo_pouch":
			case "velo_pouch_can":
			case "velo_pouch_mini":
				return "500 " + this.FONT_SIZE + "px Velo";
				break;
			default:
				return "700 " + this.FONT_SIZE + "px Gotham";
		}
	}


	modifyShader (shader)
	{
		const commonVertexChunk = [
			'attribute vec3 instanceColor;',
			'attribute vec2 instanceOffset;',
			'attribute float instanceOpacity;',
			'attribute float instanceMix;',
			'varying vec3 vInstanceColor;',
			'varying vec2 vInstanceOffset;',
			'varying float vInstanceOpacity;',
			'varying float vInstanceMix;',
			'#include <common>'
		].join( '\n' );

		const beginVertexChunk = [
			'#include <begin_vertex>',
			'vInstanceColor = instanceColor;',
			'vInstanceOffset = instanceOffset;',
			'vInstanceOpacity = instanceOpacity;',
			'vInstanceMix = instanceMix;'
		].join( '\n' );


		const commonFragChunk = [
			'varying vec3 vInstanceColor;',
			'varying vec2 vInstanceOffset;',
			'varying float vInstanceOpacity;',
			'varying float vInstanceMix;',
			'#include <common>'
		].join( '\n' );

		const diffuseFragChunk = [
			'vec4( diffuse * vInstanceColor, opacity );'
		].join( '\n' );

		const offsetFragChunk = [
			'float s = 400.0 / 4096.0;',
			'vec2 offset = vInstanceOffset + vUv;',
			'vec4 texelColor = texture2D( map, offset * s );',
			'texelColor = mapTexelToLinear( texelColor );',
			'texelColor *= vInstanceOpacity;',
			'vec3 lum = vec3(0.299, 0.587, 0.114);',
			'vec3 gry = vec3(dot(lum, texelColor.rgb));',
			'vec3 temp = mix(texelColor.rgb, gry, vInstanceMix);',
			'temp = mix(vInstanceColor.rgb, temp, texelColor.a);',
			// 'vec3 temp = mix(vInstanceColor.rgb, texelColor.rgb, texelColor.a);',
			// 'vec3 temp = mix(diffuseColor.rgb, texelColor.rgb, texelColor.a);',
			'diffuseColor = vec4(temp, 1.0);'
			// 'diffuseColor = 1.0 - ((diffuseColor - 1.0) * (texelColor - 1.0));'
		].join( '\n' );


		shader.vertexShader = shader.vertexShader
			.replace( '#include <common>', commonVertexChunk )
			.replace( '#include <begin_vertex>', beginVertexChunk );

		shader.fragmentShader = shader.fragmentShader
			.replace( '#include <common>', commonFragChunk )
			.replace( 'vec4( diffuse, opacity );', diffuseFragChunk )
			.replace( '#include <map_fragment>', offsetFragChunk);
	}
}
