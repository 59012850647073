export default class StateManager
{
	constructor ()
	{
		//States
		this.listeners = [];
		this.states = {};

		//Device change events
		this.events = [];
		this.devices = {};

		this.currentState = "";
		this.lastState = "";
	}

	/* STATES */
	registerState (stateName)
	{
		this.states[stateName] = stateName;
	}

	addStateChangeListener (changeFunction)
	{
		this.listeners.push(changeFunction);
	}

	changeState (stateName)
	{
		if (!this.states[stateName])
		{
			console.error("State " + stateName + " is undefined");
			return;
		}

		this.lastState = this.currentState;
		this.currentState = this.states[stateName];

		for (let i = 0; i < this.listeners.length; i++)
		{
			this.listeners[i](this.currentState, this.lastState);
		}
	}

	/* EVENTS */
	addEventListener (event, callback)
	{
		if (!this.events.hasOwnProperty(event))
		{
			this.events[event] = [];
		}

		this.events[event].push(callback);
	}

	publishEvent (event, data = {})
	{
		if (!this.events.hasOwnProperty(event))
		{
			return [];
		}

		return this.events[event].map(callback => callback(data));
	}
}
