import * as THREE from "three";
import gsap from "gsap";
import Math2 from "../utils/math2"

export default class DiscDefault
{
	constructor () {}

	static preload (manager)
	{
		this.manager = manager;

		this.closeImg = new Image();
		this.circleImg = new Image();
		this.btnImg = new Image();
		this.closeImg.src = this.manager.loader.getAsset("btn_close");
		this.circleImg.src = this.manager.loader.getAsset("circle");
		this.btnImg.src = this.manager.loader.getAsset("btn");

		this.bars = {};
		this.bars.bars4_0 = new Image();
		this.bars.bars4_1 = new Image();
		this.bars.bars4_2 = new Image();
		this.bars.bars4_3 = new Image();
		this.bars.bars4_4 = new Image();
		this.bars.bars4_0.src = this.manager.loader.getAsset("bars4_0");
		this.bars.bars4_1.src = this.manager.loader.getAsset("bars4_1");
		this.bars.bars4_2.src = this.manager.loader.getAsset("bars4_2");
		this.bars.bars4_3.src = this.manager.loader.getAsset("bars4_3");
		this.bars.bars4_4.src = this.manager.loader.getAsset("bars4_4");
		this.bars.bars3_0 = new Image();
		this.bars.bars3_1 = new Image();
		this.bars.bars3_2 = new Image();
		this.bars.bars3_3 = new Image();
		this.bars.bars3_0.src = this.manager.loader.getAsset("bars3_0");
		this.bars.bars3_1.src = this.manager.loader.getAsset("bars3_1");
		this.bars.bars3_2.src = this.manager.loader.getAsset("bars3_2");
		this.bars.bars3_3.src = this.manager.loader.getAsset("bars3_3");
	}

	static initTemplate (manager, discObj, current, details)
	{
		this.manager = manager;
		this.discObj = discObj;
		this.current = current;
		this.T_SIZE = 1024;

		this.units = details.units;
		this.btnMore = details.btn_more_info;
		this.btnBack = details.btn_back;
	}

	static clear (tex)
	{
		const ctx = tex.image.getContext("2d");
		ctx.setTransform(1, 0, 0, 1, 0, 0);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);
	}

	static updateFrontTexture (tex, bg)
	{
		if (!this.discObj || !this.current) console.error("Disc template vars not implemented");
		if (!this.discObj || !this.current) return;

		let json = this.discObj.json;

		const ctx = tex.image.getContext("2d");
		document.body.appendChild(ctx.canvas);
		ctx.setTransform(1, 0, 0, 1, 0, 0);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);

		//Draw background image (origami)
		if (bg)
		{
			ctx.globalAlpha = json.alpha_inside || 1.0;
			ctx.drawImage(bg, 0, 0, this.T_SIZE, this.T_SIZE);
			ctx.globalAlpha = 1.0;
		}

		//Set up text formatting
		ctx.fillStyle = "#fff";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		let px, py;

		//Reset hitboxes
		let hitboxA = this.current.getObjectByName("MORE_A");
		let hitboxB = this.current.getObjectByName("MORE_B");
		hitboxA.position.set(0, 0, 0);
		hitboxB.position.set(0, 0, 0);
		hitboxA.scale.set(0, 0, 0);
		hitboxB.scale.set(0, 0, 0);

		//Close Button
		ctx.drawImage(this.closeImg, 512 - 35, 64, 70, 70);

		//Flavour category
		if (json.category)
		{
			ctx.font = "700 30px Gotham";
			let catTxt = json.category;
			let catMtx = ctx.measureText(catTxt);

			if (catMtx.width <= 700) ctx.fillText(catTxt, 512, 200);
			else this.drawTextInRect(ctx, catTxt, 512, 180, 700, 34);
		}

		//Name
		ctx.canvas.style.letterSpacing = "2px";
		const txt = json.name.split("\n");
		if (txt.length > 2)
		{
			//Allow for three-line names
			ctx.font = "700 60px Gotham";
			for (let i = 0; i < txt.length; i++)
			{
				py = 324;
				py += (i * 60) - ((txt.length-1) * 30);
				ctx.fillText(txt[i], 512, py);
			}
		}
		else
		{
			ctx.font = "700 80px Gotham";
			for (let i = 0; i < txt.length; i++)
			{
				py = 324;
				py += (i * 80) - ((txt.length-1) * 40);
				ctx.fillText(txt[i], 512, py);
			}
		}

		//Description
		ctx.canvas.style.letterSpacing = "1px";
		ctx.font = "500 32px Gotham";
		py = this.drawTextInRect(ctx, json.descriptor, 512, 450, 900, 50);

		//If description text is too long, offset height of remaining UI
		py -= 600;
		if (py < 0) py = 0;
		const ppy = py;

		//VARIANT COLUMNS:
		px = 0;
		const vars = json.variants.length;
		if (vars > 2) console.warn("WARNING: Too many variants in disc " + json.name);
		for (let i = 0; i < vars; i++)
		{
			ctx.canvas.style.letterSpacing = "1px";
			ctx.fillStyle = "#fff";

			px = (i * 400) - ((vars-1) * 200);
			px += 512;

			if (i === 0)
			{
				hitboxA.position.set((px - 512) / 1024 * 100, -25, 2);
				if (vars <= 1) hitboxA.position.set((px - 512) / 1024 * 100, -30, 2);
				hitboxA.scale.set(0.22, 0.32, 0.22);
			}
			else if (i > 0)
			{
				hitboxB.position.set((px - 512) / 1024 * 100, -25, 2);
				hitboxB.scale.set(0.22, 0.32, 0.22);

				//Draw dividing line
				ctx.fillRect(px - 201, 600 + py, 3, 200);
			}

			//Title (multiline)
			ctx.font = "700 20px Gotham";
			py = ppy;
			const ttl = json.variants[i].title.split("\n");
			for (let i = 0; i < ttl.length; i++)
			{
				ctx.fillText(ttl[i], px, 610 + py + (i * 20));
			}
			py += (ttl.length - 1) * 20;

			//Price
			ctx.font = "700 70px Gotham";
			ctx.fillText(json.variants[i].price, px, 666 + py);

			//Strengths
			const bars = json.variants[i].bars;
			const units = json.variants[i].flavour_units;
			const strengths = json.variants[i].nic_levels;
			if (strengths.length > 0 && bars === undefined) this.drawNIC_default(strengths, units, ctx, json.color_flavour, px, py);
			if (strengths.length > 0 && bars != undefined && bars.length > 0) this.drawNIC_bars(bars, strengths, units, ctx, px, py);
			if (strengths.length > 0 && bars != undefined && bars.length === 0) this.drawNIC_percent(strengths, units, ctx, px, py);

			//No 'more' button required if no features text
			if (json.variants[i].features === undefined || json.variants[i].features === "")
			{
				//disable hitbox
				hitboxA.position.set(0, 0, 0);
				hitboxB.position.set(0, 0, 0);
				hitboxA.scale.set(0, 0, 0);
				hitboxB.scale.set(0, 0, 0);

				continue;
			}

			let py2 = py + 820;
			if (units) py2 = py + 854;

			//More Button
			ctx.canvas.style.letterSpacing = "3px";
			ctx.font = "700 24px Gotham";
			ctx.fillStyle = json.color_flavour; //"#000";
			ctx.drawImage(this.btnImg, px - 75, py2, 150, 60);
			ctx.fillText(this.btnMore, px, py2 + 32);
		}

		document.body.removeChild(ctx.canvas);

		tex.needsUpdate = true;
	}

	static updateBackTexture (tex, v, bg)
	{
		if (!this.discObj || !this.current) console.error("Disc template vars not implemented");
		if (!this.discObj || !this.current) return;

		let json = this.discObj.json;

		const ctx = tex.image.getContext("2d");
		document.body.appendChild(ctx.canvas);
		ctx.clearRect(0, 0, this.T_SIZE, this.T_SIZE);
		tex.needsUpdate = true;

		//Draw background image
		if (bg)
		{
			ctx.globalAlpha = json.alpha_inside || 1.0;
			ctx.drawImage(bg, 0, 0, this.T_SIZE, this.T_SIZE);
			ctx.globalAlpha = 1.0;
		}

		//Set up text format
		ctx.fillStyle = "#fff";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		let py;

		//Close button
		ctx.drawImage(this.closeImg, 512 - 35, 64, 70, 70);


		//vg
		ctx.font = "700 34px Gotham";
		const vgTxt = json.variants[v].vg;
		if (vgTxt !== undefined) ctx.fillText(vgTxt, 512, 375);


		//Name
		ctx.canvas.style.letterSpacing = "2px";
		const txt = json.name.split("\n");
		//I'm so sorry! One day I'll reformat this & make it better (maybe)
		if (vgTxt === undefined || vgTxt === "")
		{
			if (txt.length > 2)
			{
				//Allow for three-line names
				ctx.font = "700 60px Gotham";
				for (let i = 0; i < txt.length; i++)
				{
					py = 300;
					py += (i * 60) - ((txt.length-1) * 30);
					ctx.fillText(txt[i], 512, py);
				}
			}
			else
			{
				ctx.font = "700 80px Gotham";
				for (let i = 0; i < txt.length; i++)
				{
					py = 300;
					py += (i * 80) - ((txt.length-1) * 40);
					ctx.fillText(txt[i], 512, py);
				}
			}
		}
		else
		{
			if (txt.length > 2)
			{
				//Allow for three-line names
				ctx.font = "700 50px Gotham";
				for (let i = 0; i < txt.length; i++)
				{
					py = 250;
					py += (i * 50) - ((txt.length-1) * 25);
					ctx.fillText(txt[i], 512, py);
				}
			}
			else
			{
				ctx.font = "700 60px Gotham";
				for (let i = 0; i < txt.length; i++)
				{
					py = 250;
					py += (i * 60) - ((txt.length-1) * 30);
					ctx.fillText(txt[i], 512, py);
				}
			}
		}

		//Features (V)
		ctx.canvas.style.letterSpacing = "0px";
		ctx.font = "500 32px Gotham";
		py = this.drawTextInRect(ctx, json.variants[v].features, 512, 460, 860, 50);

		//Push text down if needed
		if (py < 646) py = 646;

		//Advice
		ctx.canvas.style.letterSpacing = "0px";
		ctx.font = "500 26px Gotham";
		py = this.drawTextInRect(ctx, json.advice, 512, py, 720, 48);

		//Push button down if needed
		if (py < 850) py = 850;

		//Back button
		ctx.canvas.style.letterSpacing = "3px";
		ctx.font = "700 24px Gotham";
		ctx.fillStyle = json.color_flavour; //"#000";
		//Fit longer button text...
		let btn_wide = ctx.measureText(this.btnBack).width;
		if (btn_wide > 110)
		{
			let pad_w = (btn_wide - 110) / 2;
			ctx.drawImage(this.btnImg, 437 - pad_w, py, 150, 60);
			ctx.drawImage(this.btnImg, 437 + pad_w, py, 150, 60);
		}
		else ctx.drawImage(this.btnImg, 437, py, 150, 60);
		ctx.fillText(this.btnBack, 512, py + 32);

		document.body.removeChild(ctx.canvas);
		tex.needsUpdate = true;
	}

	static drawTextInRect (context, text, x, y, maxWidth, lineHeight)
	{
		if (text === undefined) return;

		const words = text.split("\n");

		for (let i = 0; i < words.length; i++)
		{
			y = this.drawParagraphInRect(context, words[i], x, y, maxWidth, lineHeight);
			y += lineHeight;
		}

		return y;
	}

	static drawParagraphInRect (context, text, x, y, maxWidth, lineHeight)
	{
		const words = text.split(" ");
		let testLine, metrics;
		let line = "";

		for (let n = 0; n < words.length; n++)
		{
			testLine = line + words[n] + " ";
			metrics = context.measureText(testLine);

			if (metrics.width > maxWidth && n > 0)
			{
				context.fillText(line, x, y);
				line = words[n] + " ";
				y += lineHeight;
			}
			else
			{
				line = testLine;
			}
		}

		context.fillText(line, x, y);

		return y;
	}


	//Draw NIC strengths
	static drawNIC_default(strengths, units, ctx, col, px, py)
	{
		ctx.font = "700 22px Gotham";
		ctx.fillStyle = col;
		let sx = 0;
		for (let s = 0; s < strengths.length; s++)
		{
			sx = (s * 80) - ((strengths.length-1) * 40);
			ctx.drawImage(this.circleImg, px + sx - 28, 710 + py, 56, 56);
			ctx.fillText(strengths[s], px + sx, 738 + py);
		}

		//Check if Units override exists
		let u = this.units;
		if (units !== undefined) u = units;

		ctx.canvas.style.letterSpacing = "-1px";
		ctx.font = "600 20px Gotham";
		ctx.fillStyle = "#fff";
		ctx.fillText(u, px, 790 + py);
	}

	static drawNIC_bars(bars, strengths, units, ctx, px, py)
	{
		ctx.font = "700 28px Gotham";
		ctx.fillStyle = "#fff";
		let sx = 0, bar = "";
		for (let s = 0; s < strengths.length; s++)
		{
			if (strengths[s] === 0) ctx.font = "700 24px Gotham";
			else ctx.font = "700 28px Gotham";

			bar = "bars" + Math.max(bars.length - 1, 3) + "_" + bars.indexOf(strengths[s]);

			sx = (s * 80) - ((strengths.length-1) * 40);
			ctx.drawImage(this.bars[bar], px + sx - 25, 710 + py, 50, 50);
			ctx.fillText(this.parseNIC(strengths[s]), px + sx, 732 + py + 54);
		}

		//Units
		if (units !== undefined)
		{
			ctx.canvas.style.letterSpacing = "-1px";
			ctx.font = "600 18px Gotham";
			ctx.fillStyle = "#fff";
			ctx.fillText(units, px, 780 + py + 44);
		}
	}

	static drawNIC_percent(strengths, units, ctx, px, py)
	{
		ctx.font = "500 32px Gotham";
		ctx.fillStyle = "#fff";

		let sx = 0;
		for (let s = 0; s < strengths.length; s++)
		{
			sx = (s * 120) - ((strengths.length-1) * 60);
			ctx.fillText(strengths[s] + "%", px + sx, 764 + py);
		}

		//Units
		if (units !== undefined)
		{
			ctx.canvas.style.letterSpacing = "-1px";
			ctx.font = "600 18px Gotham";
			ctx.fillStyle = "#fff";
			ctx.fillText(units, px, 780 + py + 30);
		}
	}

	static parseNIC (nic)
	{
		if (nic === 0) return "zero";
		if (nic === "0") return "0";
		if (nic.toString().length === 1) return "0" + nic;

		return nic;
	}
}
