/*
 *  On Screen Console
 *  Sometimes I work with crappy hardware that has no dev tools
 *  This will override the browser's console & write the output to screen.
 */
export default class Console
{
	constructor ()
	{
		//Create html view
		this.domElement = document.createElement("pre");
		this.domElement.style.cssText  = "background:#000;border:1px solid #999;border-radius:10px;width:600px;text-align:left;padding:10px;opacity:0.7;overflow:visible";
		this.domElement.style.position = "absolute";
		this.domElement.style.left     = "10px";
		this.domElement.style.bottom   = "10px";
		document.body.appendChild(this.domElement);

		//Get device metrics
		const agent = navigator.userAgent;
		// let info = "SYSTEM REPORT:";
		// info += " Agent: " + navigator.userAgent;
		// info += ", Cpu: " + navigator.hardwareConcurrency + " cores";
		this.domElement.innerHTML += "<p style='color:#0f0'>Console :)</p>";
		this.domElement.innerHTML += "<p style='color:#09f'>" + agent + "</p>";
		// this.domElement.innerHTML += "<p style='color:#0f0'>" + info + "</p>";

		//settings
		this.MAX_LINES = 4;
		this.lineCount = 0;

		//counters
		this.messages = 0;
		this.warnings = 0;
		this.errors   = 0;

		//TODO:
		//Need to correctly implement everything here:
		//https://www.w3schools.com/jsref/obj_console.asp

		//Save reference to browser console
		this.refConsole(console);

		//Save scope
		const _this = this;

		//Replace console functions
		console.log = function () { _this.parseArguments(arguments, "#fff") };
		console.warn = function () { _this.parseArguments(arguments, "#ff0") };
		console.error = function () { _this.parseArguments(arguments, "#f00") };
		console.info = function () { _this.parseArguments(arguments, "#ccf") };
		console.debug = function () { _this.parseArguments(arguments, "#888") };

		// console.assert = function ()
		// {
		// 	//todo
		// }
	}

	refConsole (func)
	{
		this.echo = {};
		this.echo["#fff"] = func.log;
		this.echo["#ff0"] = func.warn;
		this.echo["#f00"] = func.error;
		this.echo["#ccf"] = func.info;
		this.echo["#888"] = func.debug;
	}

	parseArguments (args, col)
	{
		for (let i = 0; i < args.length; i++)
		{
			//Output to html
			this.write(args[i], col);

			//Echo to the browser console
			this.echo[col](args[i]);
		}
	}

	write (msg, col)
	{
		if (typeof msg == "object")
			msg = (JSON && JSON.stringify) ? JSON.stringify(msg, undefined, 2) : msg;

		this.domElement.innerHTML += "<p style='color:" + col + "'>" + msg + "</p>";

		this.lineCount++;

		if (this.lineCount > this.MAX_LINES)
		{
			const lines = this.domElement.getElementsByTagName("p");
			this.domElement.removeChild(lines[0]);

			this.lineCount--;
		}
	}
}
