import gsap from "gsap";

export default class FilterMenu
{
	constructor (manager)
	{
		this.stateMngr = manager.state;
		this.active = false;
		this.enabled = false;
		this.isOpen = false;
		this.visible = true;
		this.ready = false;
		this.toggles = [];

		//Register for device changes
		manager.state.addEventListener("nextDevice", (data) => this.reset(data));
		manager.state.addEventListener("prevDevice", (data) => this.reset(data));

		//Register disc touch events
		manager.state.addEventListener("openDisc", (data) => this.onDiscOpen(data));

		//Register resize event
		manager.addToResize((w, h) => this.onResize(w, h), "filter_menu");
	}

	init (filters, caption)
	{
		this.createElements(filters.flavours, caption);
	}

	reset ()
	{
		const filters = {};
		for (let i = 0; i < this.toggles.length;  i++)
		{
			this.addFilter(this.toggles[i]);
			filters[this.toggles[i].id] = true;
		}

		this.stateMngr.publishEvent("filterDiscs", filters);
	}

	setVisible (visible)
	{
		this.visible = visible;

		if (visible) this.show();
		else this.hide();
	}

	show ()
	{
		if (!this.ready) return;
		//Don't show if current device doesn't need filters
		if (!this.visible) return;

		this.active = true;

		//fade in
		gsap.to(this.menuElement, {
			duration: 1,
			autoAlpha: 1,
			delay: 2,
			onComplete: () =>
			{
				this.enabled = true;
				this.ringElement.style.animationPlayState = "running";
			}
		});
	}

	hide ()
	{
		if (!this.ready) return;
		this.enabled = false;

		//fade out
		gsap.to(this.menuElement, {
			duration: 1,
			autoAlpha: 0,
			delay: 0,
			onComplete: () =>
			{
				this.active = false;
				this.ringElement.style.animationPlayState = "paused";
			}
		});

		this.close(false);
		this.reset();
	}

	open ()
	{
		if (!this.ready) return;

		//Force dots anim to replay
		this.buttonElement.innerHTML = "";
		this.addMenuAnimation();
		this.updateIconCss();

		//Pause spinning gradient anim
		this.ringElement.style.animationPlayState = "paused";

		const sw = (window.innerWidth - 100) / this.toggles.length;

		//Open toggles
		gsap.killTweensOf(this.toggles);
		gsap.to(this.toggles, {
			duration: 0.5,
			autoAlpha: 1,
			x: function(index, target, targets)
			{
				const xx = (sw * (index-1)) - (sw * 0.5);
				if (index > 1) return xx + 50;
				return xx - 50;
			},
			onComplete: () =>
			{
				this.active = true;
			}
		});

		//Add flag
		this.menuElement.classList.add("is_open");
	}

	close (active = true)
	{
		if (!this.ready) return;

		//Force dots anim to replay
		this.buttonElement.innerHTML = "";
		this.addMenuAnimation();
		this.updateIconCss();

		//Play spinning gradient anim
		this.ringElement.style.animationPlayState = "running";

		//Confirm state
		this.isOpen = false;

		//Abort if no filters
		if (this.toggles.length <= 0) return;

		//Close toggles
		gsap.killTweensOf(this.toggles);
		gsap.to(this.toggles, {
			duration: 0.5,
			autoAlpha: 0,
			x: 0,
			onComplete: () =>
			{
				this.active = active;
			}
		});

		//Remove flag
		this.menuElement.classList.remove("is_open");
	}


	onDiscOpen (discId)
	{
		//TODO: Find if discId is currently filtered. If it is, don't reset
		this.reset();
		this.close();
	}

	//Scale the menu button because I can't work out how to do it with css
	onResize (w, h)
	{
		if (this.menuElement === undefined) return;

		//Calculate heights
		const scale = this.ringWrapper.offsetHeight / 128;
		const size = Math.floor(scale * 45) * 2;
		const pad = ((Math.floor(scale * 50) * 2) - size) / 2;
		const btn = size - pad - pad;

		this.ringElement.style.width = size + "px";
		this.ringElement.style.height = size + "px";
		this.ringElement.style.padding = pad + "px";
		this.ringElement.style.marginLeft = ((size + pad + pad) * -0.5) + "px";

		this.buttonElement.style.width = btn + "px";
		this.buttonElement.style.height = btn + "px";
		this.buttonElement.style.marginLeft = (btn * -0.5) + "px";
		this.buttonElement.style.marginTop = (pad * 2) + "px";

		//Move buttons to fit
		let hhh = 0;
		const limit = this.ringWrapper.offsetHeight;
		const btns = this.ringWrapper.getElementsByClassName("filter_toggle");
		for (let i = 0; i < btns.length; i++)
			hhh = Math.max(btns[i].offsetHeight, hhh);

		if (hhh > limit)
		{
			for (let i = 0; i < btns.length; i++)
				btns[i].style.marginTop = (limit - hhh) + "px";
		}

		if (size < 90) this.updateIconCss();
	}

	updateIconCss ()
	{
		let sliders = this.buttonElement.getElementsByTagName("div");
		let line, dot;

		const scale = this.ringWrapper.offsetHeight / 128;
		const size = Math.floor(scale * 45) * 2;
		const lh = Math.floor(size / 20);
		const dm = Math.floor((lh + lh + 2) * 0.5);

		for (let i = 0; i < sliders.length; i++)
		{
			line = sliders[i];
			dot = line.querySelector("span");

			line.style.height = lh + "px";
			line.style.borderRadius = Math.ceil(lh * 0.5) + "px";

			dot.style.width = (lh + 2) + "px";
			dot.style.height = (lh + 2) + "px";
			dot.style.borderWidth = lh + "px";
			dot.style.marginTop = -dm + "px";
			dot.style.marginLeft = -dm + "px";
		}
	}

	onTouch (evt)
	{
		evt.stopPropagation();
		if (!this.active) return;
		if (!this.enabled) return;

		this.isOpen = !this.isOpen;
		this.active = false;

		if (this.isOpen)
		{
			this.open();
			this.stateMngr.publishEvent("openFilterMenu", undefined);
		}
		else this.close();
	}

	onFilterTouch (evt)
	{
		evt.stopPropagation();
		if (!this.active) return;
		if (!this.enabled) return;

		const state = !evt.target.classList.contains("isOff");
		const allOn = this.checkAllToggles(true);

		if  (allOn)
		{
			for (let i = 0; i < this.toggles.length;  i++)
			{
				if (evt.target.id == this.toggles[i].id) this.addFilter(this.toggles[i]);
				else this.removeFilter(this.toggles[i]);
			}
		}
		else
		{
			if (state) this.removeFilter(evt.target);
			else this.addFilter(evt.target);
		}

		const allOff = this.checkAllToggles(false);

		if (allOff)
		{
			for (let i = 0; i < this.toggles.length;  i++)
			{
				this.addFilter(this.toggles[i]);
			}
		}

		//Send event
		const filters = {};
		for (let i = 0; i < this.toggles.length;  i++)
		{
			filters[this.toggles[i].id] = !this.toggles[i].classList.contains("isOff");
		}

		this.stateMngr.publishEvent("filterDiscs", filters);
	}

	checkAllToggles (match)
	{
		let cont;

		for (let i = 0; i < this.toggles.length;  i++)
		{
			cont = this.toggles[i].classList.contains("isOff");

			if (match && cont) return false;
			if (!match && !cont) return false;
		}

		return true;
	}

	addFilter (obj)
	{
		obj.classList.remove("isOff");
		//add to list
	}

	removeFilter (obj)
	{
		obj.classList.add("isOff");
		//remove from list
	}


	createElements (filters, caption)
	{
		const root = document.querySelector("#bat_UI");

		this.menuElement = document.createElement("div");
		this.menuElement.style.visibility = "hidden";
		this.menuElement.style.opacity = 0;
		this.menuElement.className = "filterMenu";
		root.appendChild(this.menuElement);

		//Text caption
		this.titleElement = document.createElement("div");
		this.titleElement.innerHTML = "<span>" + caption.replace("\n", "</span><span>") + "</span>";
		this.titleElement.className = "caption";
		this.menuElement.appendChild(this.titleElement);

		//Ring wrapper
		this.ringWrapper = document.createElement("div");
		this.ringWrapper.className = "filter_wrapper";
		this.menuElement.appendChild(this.ringWrapper);

		//Gradient ring
		this.ringElement = document.createElement("div");
		this.ringElement.className = "filter_ring";
		this.ringElement.appendChild(document.createElement("div"));
		this.ringWrapper.appendChild(this.ringElement);

		//Toggle button
		this.buttonElement = document.createElement("div");
		this.buttonElement.className = "filter_button";
		this.addMenuAnimation();
		this.ringWrapper.appendChild(this.buttonElement);

		let filter, tick, line, txt;

		//Filter buttons
		for (let i = 0; i < filters.length; i++)
		{
			filter = document.createElement("div");
			filter.className = "filter_toggle";
			// filter.classList.add("toggled");
			filter.style.visibility = "hidden";
			filter.style.opacity = 0;
			filter.id = filters[i].id;

			tick = document.createElement("div");
			tick.className = "tick";
			tick.style.backgroundColor = filters[i].color1;
			filter.appendChild(tick);

			line = document.createElement("div");
			line.className = "line";
			line.style.backgroundImage = "linear-gradient(90deg, " + filters[i].color1 + ", " + filters[i].color2 + ")";
			line.appendChild(document.createElement("div"));
			filter.appendChild(line);

			txt = document.createElement("p");
			txt.innerHTML = filters[i].name;
			txt.style.color = filters[i].color2;
			filter.appendChild(txt);

			this.ringWrapper.appendChild(filter);
			this.toggles.push(filter);

			//add touch listener
			filter.addEventListener("mousedown", evt => this.onFilterTouch(evt), false);
		}

		//add touch listener
		this.buttonElement.addEventListener("mousedown", evt => this.onTouch(evt), false);

		//call resize to adjust everything
		this.onResize();

		this.ready = true;
	}

	addMenuAnimation ()
	{
		let d, s;

		for (let i = 0; i < 3; i++)
		{
			d = document.createElement("div");
			s = document.createElement("span");
			// s.appendChild(document.createElement("div"));
			d.appendChild(s);
			this.buttonElement.appendChild(d);
		}
	}
}
