/*
 *  Flavour Menu
 *  A 3D cloud of discs representing different flavours
 */

import * as THREE from "three";
import gsap from "gsap";
import DiscView from "./disc_view";
import DiscPoolBuilder from "./disc_pool";

export default class FlavourDiscs extends THREE.Group
{
	constructor (manager)
	{
		super();

		this.manager = manager;
		this.enabled = false;
		this.active = false;
		this.opening = false;
		this.discCount = 0;
		this.currentDisc = -1;

		//disc pool builder
		this.discPool = new DiscPoolBuilder(manager);

		//disc view
		this.discView = new DiscView(manager);

		//Raycasting stuff
		this.camera = manager.camera;
		this.stateMngr = manager.state;
		this.raycaster = new THREE.Raycaster();
		this.instanceMatrix = new THREE.Matrix4();
		this.mouse = new THREE.Vector2(1, 1);
		this.intersect = [];

		manager.add(this);
		manager.addToUpdate(() => this.update(), "discmenu");
		manager.addToEvents(() => this.bindEvents(), "discmenu");

		//Register for device changes
		manager.state.addEventListener("changeDevice", (id) => this.changeDevice(id));
		manager.state.addEventListener("nextDevice", (id) => this.showNextDevice(id));
		manager.state.addEventListener("prevDevice", (id) => this.showPrevDevice(id));

		//Register disc touch events
		manager.state.addEventListener("openingDisc", (data) => this.openDisc(data));
		manager.state.addEventListener("flipDisc", (data) => this.flipDisc(data));
		manager.state.addEventListener("unflipDisc", (data) => this.unflipDisc(data));
		manager.state.addEventListener("closeDisc", (data) => this.closeDisc(data));

		//Register filter change event
		manager.state.addEventListener("openFilterMenu", () => this.onFilterMenu());
		manager.state.addEventListener("filterDiscs", (list) => this.onFilterDiscs(list));
	}

	init (json, positions, obj, details)
	{
		this.discView.init(details);

		// const mesh = obj.scene.getObjectByName("Disc_Flat");
		// this.initNow(json, positions, mesh);

		for (let i = 0; i < obj.children.length; i++)
		{
			if (obj.children[i].isMesh)
			{
				this.initNow(json, positions, obj.children[i]);
				return;
			}
		}
	}

	initNow (json, positions, mesh)
	{
		this.discPool.init(json, positions, mesh);
		this.add(this.discPool.mesh);
	}

	show ()
	{
		if (this.currentDevice === undefined)
		{
			console.error("ERROR: Device not defined");
			return;
			// this.changeDevice({"deviceId": "epen3"});
		}

		this.active = true;

		for (let i = 0; i < this.discPool.discCount; i++)
		{
			this.discPool.currentPool[i].show(i * 0.05);
		}

		gsap.delayedCall(2, () => {
			this.enabled = true;
		});
	}

	hide ()
	{
		this.enabled = false;

		this.closeDisc(this.currentDisc);

		for (let i = 0; i < this.discPool.discCount; i++)
		{
			this.discPool.currentPool[i].hide();
		}

		gsap.delayedCall(1, () => {
			this.active = false;
		});
	}

	changeDevice (deviceObj)
	{
		//Clear any discs currently on screen
		for (let i = 0; i < this.discPool.discCount; i++)
		{
			this.discPool.currentPool[i].reset();
		}

		if (this.discView.isOpen)
		{
			this.discView.reset();
			this.currentDisc = -1;
		}
		this.camera.reset();

		//Change device
		this.currentDevice = deviceObj.deviceId;
		this.discPool.changeDevice(deviceObj.deviceId);

		this.active = false;
		this.enabled = false;
	}

	showNextDevice (deviceId)
	{
		this.discPool.showNextDevice(deviceId);
	}

	showPrevDevice (deviceId)
	{
		this.discPool.showPrevDevice(deviceId);
	}


	openDisc (discId)
	{
		//Prevent another disc being opened while loading bg image
		if (this.opening) return;
		this.opening = true;

		this.closeDisc(this.currentDisc);

		this.currentDisc = discId;
		const discObj = this.discPool.getDiscById(discId);

		//Check if we need to load a background image before the transition
		const bg = discObj.json.background_image;
		if (bg === undefined)
		{
			this.openDiscTransition(discObj);
		}
		else
		{
			//Load background image
			const img = new Image();
			img.crossOrigin = "";
			img.onload = e => this.applyBgImage(e, discObj);

			//Replace this with fetch
			// img.src = bg;
			// img.src = "https://uat-assets-bat.s3-eu-west-1.amazonaws.com/education/origami/eliquid_blended_tobacco.png";

			fetch(bg + "?cacheblock=true", {
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				headers: {
					Origin: window.location.origin
				}
			})
			.then(response => {
				if (!response.ok) {
					throw new Error("NETWORK ERROR: Fetch failed to load disc background");
				}
				//chris code:
				return response.blob().then(parsed => parsed);
			})
			.then(blob => {
				img.src = URL.createObjectURL(blob);
			})
			.catch(error => {
				console.error("ERROR: Fetch request failed", error);
			});
		}
	}

	applyBgImage (e, discObj)
	{
		let that = this;
		Promise.resolve(e.currentTarget).then(function() {
			that.openDiscTransition(discObj, e.currentTarget);
		});

		//NOTE: After various testing, I can confirm that the bg image loaded
		//does definitely exist when openDiscTransition is called.
	}

	openDiscTransition (discObj, bg)
	{
		this.opening = false;

		//Dispatch final open event
		this.stateMngr.publishEvent("openDisc", this.currentDisc);

		//Zoom camera in
		this.discPool.mesh.getMatrixAt(this.currentDisc, this.instanceMatrix);
		this.camera.setTarget(this.instanceMatrix);

		//Update disc view
		this.discView.open(discObj, bg);

		//Send analytics event
		this.manager.state.publishEvent("analytics", {"event": "flavour", "data": discObj.json.id});

		discObj.open();
	}

	flipDisc (discId)
	{
		this.discPool.getDiscById(discId).flip();
	}

	unflipDisc (discId)
	{
		this.discPool.getDiscById(discId).unflip();
	}

	closeDisc (discId)
	{
		if (discId === -1) return;

		//Zoom camera out
		this.camera.target.copy(this.camera.origin);

		//Hide the disc view
		const discObj = this.discPool.getDiscById(discId);
		this.discView.close(discObj);
		discObj.close();

		this.currentDisc = -1;
	}

	onFilterDiscs (toggles)
	{
		const active = [];
		let discFilters, isFiltered;

		Object.keys(toggles).forEach(function(key) {
		    if (toggles[key]) active.push(key);
		});

		for (let i = 0; i < this.discPool.discCount; i++)
		{
			discFilters = this.discPool.currentPool[i].filters;
			for (let j = 0; j < discFilters.length; j++)
			{
				isFiltered = discFilters.some(r => active.includes(r));
				this.discPool.currentPool[i].setFiltered(isFiltered);
			}
		}
	}

	onFilterMenu ()
	{
		this.closeDisc(this.currentDisc);
	}

	update ()
	{
		if (!this.active) return;

		this.discPool.update();
	}

	bindEvents ()
	{
		const root = document.querySelector("#bat_UI");
		root.addEventListener("mousedown", evt => this.onTouch(evt), false);
	}

	onTouch (event)
	{
		event.preventDefault();
		if (!this.active) return;
		if (!this.enabled) return;

		const win = this.manager.canvas.getBoundingClientRect();
		const mx = event.clientX - win.left;
		const my = event.clientY - win.top;
		this.mouse.x = (mx / win.width) * 2 - 1;
		this.mouse.y = - (my / win.height) * 2 + 1;

		this.intersect.length = 0;
		this.raycaster.setFromCamera(this.mouse, this.camera);
		this.raycaster.intersectObject(this.discPool.mesh, false, this.intersect);

		if (this.intersect.length > 0)
		{
			let id = this.intersect[0].instanceId;
			if (this.currentDisc === id) this.discView.onTouch(id, this.mouse);
			else this.stateMngr.publishEvent("openingDisc", id);
		}
		else
		{
			this.stateMngr.publishEvent("closeDisc", this.currentDisc);
		}
	}
}
