/*
 *  Device Helper
 *  Use this as an object to manage device position & movement
 */

import * as THREE from "three";
import gsap from "gsap";
import Math2 from "./utils/math2"

export default class DeviceHelper extends THREE.Object3D
{
	constructor (json, id, country, edu = false, landscape = true, dcount = 3)
	{
		super();

		this.name = id;
		this.hidden = false;
		this.shifted = false;

		this.mtx = {};

		//UV coords
		this.mtx.uvx = json.uv[0];
		this.mtx.uvy = json.uv[1];
		this.mtx.uvw = json.uv[2];
		this.mtx.uvz = json.uv[3];

		//Check for country specific UV coords
		if (json.hasOwnProperty("uv_" + country))
		{
			const uv = json["uv_" + country];
			this.mtx.uvx = uv[0];
			this.mtx.uvy = uv[1];
			this.mtx.uvw = uv[2];
			this.mtx.uvz = uv[3];
		}

		//Scale
		this.mtx.sx = json.size[0];
		this.mtx.sy = json.size[1];
		this.mtx.sz = 1.0;

		//Check for country specific size
		if (json.hasOwnProperty("size_" + country))
		{
			const sz = json["size_" + country];
			this.mtx.sx = sz[0];
			this.mtx.sy = sz[1];
		}

		//Hidden?
		if (json.hidden !== undefined)
		{
			for (let i = 0; i < json.hidden.length; i++)
			{
				if (json.hidden[i] === country) this.hidden = true;
			}
		}

		if (edu)
		{
			this.mtx.spx = this.mtx.spy = this.mtx.spz = 0;
			this.mtx.srx = this.mtx.sry = this.mtx.srz = 0;
			this.mtx.px = this.mtx.py = this.mtx.pz = 0;

			//Set defaults
			this.position.set(0, 0, 0);
			this.rotation.set(0, 0, 0);
			this.scale.set(this.mtx.sx, this.mtx.sy, this.mtx.sz);
			this.opacityFlag = true;
			this.alpha = 0;

			this.currentPos = new THREE.Vector3();
			this.currentPos.copy(this.position);

			this.updateMatrix();
			return;
		}

		//Start position
		this.mtx.spx = json.pos_from.x;
		this.mtx.spy = json.pos_from.y;
		this.mtx.spz = json.pos_from.z;

		//Position
		this.mtx.px = json.pos.x;
		this.mtx.py = json.pos.y;
		this.mtx.pz = json.pos.z;

		//For 4 devices, use hard coded values from json
		if (dcount === 4)
		{
			this.mtx.spx = json.pos_from_4.x;
			this.mtx.spy = json.pos_from_4.y;
			this.mtx.spz = json.pos_from_4.z;

			this.mtx.px = json.pos_4.x;
			this.mtx.py = json.pos_4.y;
			this.mtx.pz = json.pos_4.z;
		}

		// //For 1 device, use hard coded values from json
		// if (dcount === 1)
		// {
		// 	this.mtx.spx = json.pos_from_1.x;
		// 	this.mtx.spy = json.pos_from_1.y;
		// 	this.mtx.spz = json.pos_from_1.z;

		// 	this.mtx.px = json.pos_1.x;
		// 	this.mtx.py = json.pos_1.y;
		// 	this.mtx.pz = json.pos_1.z;
		// }

		//Portrait
		if (!landscape)
		{
			this.mtx.spx = json.port_pos_from.x;
			this.mtx.spy = json.port_pos_from.y;
			this.mtx.spz = json.port_pos_from.z;

			this.mtx.px = json.port_pos.x;
			this.mtx.py = json.port_pos.y;
			this.mtx.pz = json.port_pos.z;

			this.mtx.sx = json.port_size[0];
			this.mtx.sy = json.port_size[1];
			this.mtx.sz = 1.0;

			//For 4 devices, use hard coded values from json
			if (dcount === 4)
			{
				this.mtx.spx = json.port_pos_from_4.x;
				this.mtx.spy = json.port_pos_from_4.y;
				this.mtx.spz = json.port_pos_from_4.z;

				this.mtx.px = json.port_pos_4.x;
				this.mtx.py = json.port_pos_4.y;
				this.mtx.pz = json.port_pos_4.z;

				this.mtx.sx = json.port_size_4[0];
				this.mtx.sy = json.port_size_4[1];
				this.mtx.sz = 1.0;
			}

			// //For 1 device, use hard coded values from json
			// if (dcount === 1)
			// {
			// 	this.mtx.spx = json.port_pos_from_1.x;
			// 	this.mtx.spy = json.port_pos_from_1.y;
			// 	this.mtx.spz = json.port_pos_from_1.z;

			// 	this.mtx.px = json.port_pos_1.x;
			// 	this.mtx.py = json.port_pos_1.y;
			// 	this.mtx.pz = json.port_pos_1.z;

			// 	this.mtx.sx = json.port_size_1[0];
			// 	this.mtx.sy = json.port_size_1[1];
			// 	this.mtx.sz = 1.0;
			// }
		}

		//Start rotation
		this.mtx.srx = json.rot_from.x;
		this.mtx.sry = json.rot_from.y;
		this.mtx.srz = json.rot_from.z;


		this.position.set(this.mtx.spx, this.mtx.spy, this.mtx.spz);
		this.rotation.set(this.mtx.srx, this.mtx.sry, this.mtx.srz);
		this.scale.set(this.mtx.sx, this.mtx.sy, this.mtx.sz);
		this.opacityFlag = true;
		this.alpha = 0;

		this.currentPos = new THREE.Vector3();
		this.currentPos.copy(this.position);

		this.updateMatrix();
	}

	show (delay, fromStart = false)
	{
		if (fromStart)
		{
			this.position.set(this.mtx.spx, this.mtx.spy, this.mtx.spz);
			this.rotation.set(this.mtx.srx, this.mtx.sry, this.mtx.srz);
			this.scale.set(this.mtx.sx, this.mtx.sy, this.mtx.sz);
			this.updateMatrix();
		}

		gsap.to(this.position, {
			duration: 1,
			delay: delay,
			x: this.mtx.px,
			y: this.mtx.py,
			z: this.mtx.pz
		});

		gsap.to(this.rotation, {
			duration: 1,
			delay: delay,
			x: 0,
			y: 0,
			z: 0
		});

		gsap.to(this.scale, {
			duration: 1,
			delay: delay,
			x: this.mtx.sx,
			y: this.mtx.sy,
			z: this.mtx.sz
		});

		gsap.to(this, {
			duration: 1,
			delay: delay,
			alpha: 1,
			onUpdate: () =>
			{
				this.opacityFlag = true;
			}
		});
	}

	hide (delay)
	{
		gsap.to(this, {
			duration: 1,
			delay: 0,
			alpha: 0,
			onUpdate: () =>
			{
				this.opacityFlag = true;
			}
		});
	}

	shiftForwards ()
	{
		if (this.shifted) return;

		this.currentPos.copy(this.position);
		this.shifted = true;

		gsap.to(this.position, {
			duration: 1,
			z: this.currentPos.z + 1000
		});
	}

	shiftBackwards ()
	{
		this.shifted = false;

		gsap.to(this.position, {
			duration: 1,
			z: this.currentPos.z
		});
	}

	getAlpha ()
	{
		this.opacityFlag = false;
		return this.alpha;
	}

	setAlpha (value)
	{
		//Don't change alpha if device should stay hidden
		if (this.hidden) value = 0;

		this.alpha = value;
		this.opacityFlag = true;
	}

	animate ()
	{
		this.updateMatrix();
	}


	createDebugRect ()
	{
		this.root = document.querySelector("#bat_UI");
		this.debugRect = document.createElement("div");
		this.debugRect.style.position = "absolute";
		this.debugRect.style.border = "1px solid yellow";
		this.debugRect.style.pointerEvents = "none";
		this.root.appendChild(this.debugRect);
	}
	updateDebugRect (rect)
	{
		if (this.debugRect === undefined) this.createDebugRect();

		let w = rect.width;
		let h = rect.height;
		let x = rect.left;
		let y = rect.top;

		this.debugRect.style.left = x + "px";
		this.debugRect.style.top = y + "px";
		this.debugRect.style.width = w + "px";
		this.debugRect.style.height = h + "px";
	}
}
