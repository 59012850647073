import disc from "../obj/disc_flat.fbx";
import devices_tx from "../textures/devices.png";
import discs_pos from "../json/positions.json";
import devices_pos from "../json/devices.json";
import devices_edu from "../json/devices_edu.json";
import btn_close from "../textures/close_btn.png";
import btn_close_black from "../textures/close_btn_black.png";
import circle from "../textures/circle.png";
import btn from "../textures/btn.png";
import btn_black from "../textures/btn_black.png";
import dots_0 from "../textures/dots_0.png";
import dots_1 from "../textures/dots_1.png";
import dots_2 from "../textures/dots_2.png";
import dots_3 from "../textures/dots_3.png";
import dots_4 from "../textures/dots_4.png";
import bars4_0 from "../textures/bars4_0.png";
import bars4_1 from "../textures/bars4_1.png";
import bars4_2 from "../textures/bars4_2.png";
import bars4_3 from "../textures/bars4_3.png";
import bars4_4 from "../textures/bars4_4.png";
import bars3_0 from "../textures/bars3_0.png";
import bars3_1 from "../textures/bars3_1.png";
import bars3_2 from "../textures/bars3_2.png";
import bars3_3 from "../textures/bars3_3.png";

import px from "../textures/px.png";
import py from "../textures/py.png";
import pz from "../textures/pz.png";
import nx from "../textures/nx.png";
import ny from "../textures/ny.png";
import nz from "../textures/nz.png";

const assets = [
	{ id: "Gotham", weight: "500", type: "font" },
	{ id: "Gotham", weight: "600", type: "font" },
	{ id: "Gotham", weight: "700", type: "font" },
	{ id: "Din", weight: "400", type: "font" },
	{ id: "Din", weight: "500", type: "font" },
	{ id: "Din", weight: "600", type: "font" },
	{ id: "Din", weight: "800", type: "font", style: "italic" },
	{ id: "Velo", weight: "400", type: "font" },
	{ id: "Velo", weight: "500", type: "font" },
	{ id: "Velo", weight: "600", type: "font" },
	{ id: "disc", uri: disc, type: "fbx" },
	{ id: "devices_tx", uri: devices_tx, type: "tex" },
	{ id: "discs_pos", data: discs_pos, type: "data" },
	{ id: "devices_pos", data: devices_pos, type: "data" },
	{ id: "devices_edu", data: devices_edu, type: "data" },
	{ id: "btn_close", data: btn_close, type: "data" },
	{ id: "btn_close_black", data: btn_close_black, type: "data" },
	{ id: "circle", data: circle, type: "data" },
	{ id: "btn", data: btn, type: "data" },
	{ id: "btn_black", data: btn_black, type: "data" },
	{ id: "dots_0", data: dots_0, type: "data" },
	{ id: "dots_1", data: dots_1, type: "data" },
	{ id: "dots_2", data: dots_2, type: "data" },
	{ id: "dots_3", data: dots_3, type: "data" },
	{ id: "dots_4", data: dots_4, type: "data" },
	{ id: "bars4_0", data: bars4_0, type: "data" },
	{ id: "bars4_1", data: bars4_1, type: "data" },
	{ id: "bars4_2", data: bars4_2, type: "data" },
	{ id: "bars4_3", data: bars4_3, type: "data" },
	{ id: "bars4_4", data: bars4_4, type: "data" },
	{ id: "bars3_0", data: bars3_0, type: "data" },
	{ id: "bars3_1", data: bars3_1, type: "data" },
	{ id: "bars3_2", data: bars3_2, type: "data" },
	{ id: "bars3_3", data: bars3_3, type: "data" }
];

export default assets;

export const environment = [px, py, pz, nx, ny, nz];
